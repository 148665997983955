<template>
    <div>
        <b-modal id="insuficient-qad" centered header-class="border-0 pt-4 pb-0" :hide-footer="true" :hide-header="true" footer-class="border-0 pb-3 pt-0 mx-auto" size="sm">
            <div class="float-right">
                <button class="btn d-flex justify-content-center align-items-center pr-0" @click="$bvModal.hide('insuficient-qad')"><img src="@/assets/images/icons/ic_close.svg" alt=""></button>
            </div>
            <div class="mt-5 text-center text-white mb-3">
                <img src="@/assets/images/icons/modal_warning.svg" alt="Alert" class="img-fluid">
                <div class="mt-3">
                    <h5 class="font-weight-bold mb-2" style="font-size:16px;">Your Multilingual credit insufficient</h5>
                    <h5 class="font-weight-normal" style="font-size:14px;">The current credit amount is not enough for the duration of videos you want to upload.</h5>
                </div>
                <div class="action d-flex justify-content-center mt-3">
                    <button class="btn iqad__button-cancel" @click="$bvModal.hide('insuficient-qad')"><p class="mx-2 mb-0 font-weight-bold text-white">Cancel</p></button>
                    <button class="btn iqad__button-oke" @click="handleClickUpgrade"><p class="mx-2 mb-0 font-weight-bold text-white">Buy Now</p></button>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
export default {
    props: {
        clickUpgrade: {
            type: Function,
            default: null,
        },
    },
    methods: {
        handleClickUpgrade() {
            if (this.clickUpgrade !== null) {
                if(this.$route.name == 'Generate-Text-To-Audio') {
                    this.$emit('handle-upgrade-tta')
                } else if (this.$route.name == 'Generate-Videos'){
                    this.$emit('click:handle-upgrade-gv')
                } else {
                    this.clickUpgrade();
                }
            } else {
                this.$router.push({ name: 'TopUp' });
            }
        },
    },
};
</script>

<style scoped>
.action {
    gap: 8px;
}

.iqad__button-cancel, .iqad__button-oke {
  border-radius: 4px;
  font-size: 15px;
  font-weight: 500;
  transition: background-color 0.25s;
  color: #fff;
}

.iqad__button-cancel {
  background-color: #2d2d2d;
}

.iqad__button-cancel:hover {
  background-color: #2C2C2C;
}

.iqad__button-oke {
  background-color: #6D75F6;
}

.iqad__button-oke:hover {
  background-color: #545EED;
}
</style>

<style>

@font-face {
  font-family: "Avenir Next";
  src: url("../../assets/font/AvenirNextLTPro-Regular.otf");
}
@font-face {
  font-family: "Avenir Next";
  src: url("../../assets/font/AvenirNextLTPro-Bold.otf");
  font-weight: 900;
}
@font-face {
  font-family: "Avenir Next";
  src: url("../../assets/font/AvenirNext-DemiBold.ttf");
  font-weight: bold;
}
@import url("https://fonts.googleapis.com/css2?family=Muli:wght@300;400;500;600;700;800&display=swap");

#insuficient-qad .modal-content{
    background-color: #222222;
     font-family: "Avenir Next", Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


#insuficient-qad .modal-content{
    border-radius: 10px !important;
    background-color: #1F1F1F;
}

.btn-purple {
    background-color: #6D75F6;
    border: 1px solid #6D75F6;
    color: white;
    box-shadow: none !important;
}
#insuficient-qad___BV_modal_backdrop_.modal-backdrop {
    background: rgb(0, 0, 0, 0.5) !important
}
</style>