<template>
	<b-modal :id="id" centered hide-header hide-footer dialog-class="usrt__dialog d-flex align-items-center justify-content-center" content-class="usrt" size="md" @hidden="handleHideUploadSrt">
        <div class="d-flex justify-content-end">
            <button class="btn d-flex p-0 button--close-srt justify-content-center align-items-center pr-0" @click="$bvModal.hide(id)"><b-icon icon="x"></b-icon></button>
        </div>
		<div class="usrt--content d-flex flex-column container-fluid">
			<h5 class="usrt--content__title">
				Upload SRT File
			</h5>
			<p class="usrt--content__desc m-0">Improve accuracy by uploading files for both the original and translated languages.</p>
			<div class="usrt--content__list w-100 mt-3">
				<div class="usrt--content__card">
					<p v-if="fileSrt1.file == null" class="usrt--text usrt--text__disable m-0">Original Language</p>
					<div v-else class="dropdown w-100">
						<button
							:id="`${_uid}__choose-language-1`"
							ref='buttonLang1'
							class="btn btn-dropdown p-0 d-flex align-items-center w-100"
							type="button"
							data-toggle="dropdown"
							aria-haspopup="true"
							aria-expanded="false"
						>
							<img :src="require('@/assets/images/icons/ic_flag_indonesia.svg')" class="img-fluid mr-2" style="width:15px; height: 15px;">
							<p class="usrt--text m-0">Indonesia</p>
							<div class="ml-auto">
								<svg xmlns="http://www.w3.org/2000/svg" width="10" height="6" viewBox="0 0 10 6" fill="none">
									<path d="M1 1L5 5L9 1" stroke="white"/>
								</svg>
							</div>
						</button>
						<div class="dropdown-menu dropdown-menu-lang dropdown-menu-right mt-2" :aria-labelledby="`${_uid}__choose-language-1`">
							<div class="px-1">
								<div class="select-lang overflow-auto px-1">
									<button v-for="country in sortedCountries" :key="country.code + country.name" class="btn btn-choose d-block w-100 text-left">
										<div class="d-flex align-items-center">
											<img
												:src="country.flagSrc"
												class="img-fluid"
												style="width: 18px; height: 18px"
											/>
											<p class="ad-uc__drop-wrap--text m-0 ml-2">{{ country.name }}</p>
											<img src="@/assets/images/icons/ic_news.svg" alt="" class="ml-2" v-if="country.name == 'Multilingual'">
										</div>
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="usrt--content__card">
					<button v-if="fileSrt1.file == null" class="btn btn--upload-srt d-flex align-items-center p-0 w-100" @click="openFile(1)">
						<img src="@/assets/images/icons/ic_usrt2.svg" alt="">
						<p class="mb-0">upload SRT</p>
					</button>
					<div v-else class="d-flex align-items-center justify-content-between w-100">
						<p class="text-white font-weight-bold m-0 text-truncate" :title="fileSrt1.name">{{ fileSrt1.name }}</p>
						<button title="delete file" class="btn--delete-file btn d-flex align-items-center justify-content-center" @click="deleteSrt(1)">
							<img src="@/assets/images/icons/ic_usrt-delete.svg" alt="">
						</button>
					</div>
					<input class="d-none" type="file" ref="uploadSrt1" accept=".srt" @change="uploadFile($event, 1)">
				</div>

				<div class="usrt--content__card">
					<p v-if="fileSrt2.file == null" class="usrt--text usrt--text__disable m-0">Translated Language</p>
					<div v-else class="dropdown w-100">
						<button
							:id="`${_uid}__choose-language-2`"
							ref='buttonLang2'
							class="btn btn-dropdown p-0 d-flex align-items-center w-100"
							type="button"
							data-toggle="dropdown"
							aria-haspopup="true"
							aria-expanded="false"
						>
							<img :src="require('@/assets/images/icons/ic_flag_indonesia.svg')" class="img-fluid mr-2" style="width:15px; height: 15px;">
							<p class="usrt--text m-0">Indonesia</p>
							<div class="ml-auto">
								<svg xmlns="http://www.w3.org/2000/svg" width="10" height="6" viewBox="0 0 10 6" fill="none">
									<path d="M1 1L5 5L9 1" stroke="white"/>
								</svg>
							</div>
						</button>
						<div class="dropdown-menu dropdown-menu-lang dropdown-menu-right mt-2" :aria-labelledby="`${_uid}__choose-language-2`">
							<div class="px-1">
								<div class="select-lang overflow-auto px-1">
									<button v-for="country in sortedCountries" :key="country.code + country.name" class="btn btn-choose d-block w-100 text-left">
										<div class="d-flex align-items-center">
											<img
												:src="country.flagSrc"
												class="img-fluid"
												style="width: 18px; height: 18px"
											/>
											<p class="ad-uc__drop-wrap--text m-0 ml-2">{{ country.name }}</p>
											<img src="@/assets/images/icons/ic_news.svg" alt="" class="ml-2" v-if="country.name == 'Multilingual'">
										</div>
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="usrt--content__card">
					<button v-if="fileSrt2.file == null" class="btn btn--upload-srt d-flex align-items-center p-0" @click="openFile(2)">
						<img src="@/assets/images/icons/ic_usrt2.svg" alt="">
						<p class="mb-0">upload SRT</p>
					</button>
					<div v-else class="d-flex align-items-center justify-content-between w-100">
						<p class="text-white font-weight-bold m-0 text-truncate" :title="fileSrt1.name">{{ fileSrt2.name }}</p>
						<button title="delete file" class="btn--delete-file btn d-flex align-items-center justify-content-center" @click="deleteSrt(2)">
							<img src="@/assets/images/icons/ic_usrt-delete.svg" alt="">
						</button>
					</div>
					<input class="d-none" type="file" ref="uploadSrt2" accept=".srt" @change="uploadFile($event, 2)">
				</div>
			</div>
			<button :disabled="!fileSrt1.file && !fileSrt2.file" class="btn btn--usrt-submit mx-auto mt-4" @click="handleSubmitFileSrt">Submit</button>
		</div>
    </b-modal>
</template>

<script>
export default {
	props: {
		id: {
			type: String,
			default: 'upload-srt-ad-modal'
		},
		languages: {
			type: Array,
			default: () => {
				return []
			}
		}
	},
	computed: {
		sortedCountries() {
			if (this.languages.length > 0) {
				const sortedLanguages = [...this.languages];

				sortedLanguages.sort((a, b) => {
				const nameA = a.name.toLowerCase();
				const nameB = b.name.toLowerCase();

				if (nameA === 'multilingual') {
					return -1; // Pindahkan 'Multilingual' ke atas
				}

				if (nameB === 'multilingual') {
					return 1; // Pindahkan 'Multilingual' ke atas
				}

				if (nameA < nameB) {
					return -1;
				}

				if (nameA > nameB) {
					return 1;
				}

				return 0;
				});

				return sortedLanguages;
			} else {
				return this.languages;
			}
		},
	},
	data() {
		return {
			fileSrt1: {
				file: null,
				name: null,
			},
			fileSrt2: {
				file: null,
				name: null,
			}
		}
	},
	methods: {
		openFile(number) {
			if(number == 1) {
				this.$refs.uploadSrt1.click();
			} else {
				this.$refs.uploadSrt2.click();
			}
		},
		uploadFile(event, number) {
			const file = event.target.files[0];
			if (!file) return;

			// Validasi ekstensi file
			const allowedExtensions = ['.srt'];
			const fileExtension = file.name.substring(file.name.lastIndexOf('.'));

			if (!allowedExtensions.includes(fileExtension)) {
				this.alertFailIcon('File Extension not allowed');
				return;
			}

			const maxSizeMB = 50;
			const maxSizeBytes = maxSizeMB * 1024 * 1024;

			if (file.size > maxSizeBytes) {
				this.alertFailIcon('File size must less than 50MB');
				return;
			}

			const reader = new FileReader();
			reader.onload = () => {
			const fileDataUrl = reader.result;
				if(number == 1) {
					this.fileSrt1.file = fileDataUrl
					this.fileSrt1.name = file.name
				} else {
					this.fileSrt2.file = fileDataUrl
					this.fileSrt2.name = file.name
				}
			};
			reader.readAsDataURL(file);

		},

		deleteSrt(number) {
			if(number == 1) {
				this.fileSrt1.file = null
				this.fileSrt1.name = null
			} else {
				this.fileSrt2.file = null
				this.fileSrt2.name = null
			}
		},
		handleHideUploadSrt() {
			this.fileSrt1.file = null
			this.fileSrt1.name = null
			this.fileSrt2.file = null
			this.fileSrt2.name = null
		},
		handleSubmitFileSrt() {
			this.$emit('click:upload-srt', { file1: this.fileSrt1, file2: this.fileSrt2 })
		}
	}
}
</script>

<style scoped>
.button--close-srt {
	width: 28px;
	height: 28px;
	font-size: 16px;
	border-radius: 50%;
	color: #000;
	background-color: #fff;
}

.usrt--content {
	gap: 9px;
	color: #fff;
}

.usrt--content__title {
	font-size: 20px;
	font-weight: 600;
}

.usrt--content__desc {
	font-size: 14px;
}

.usrt--content__list {
	display: grid;
	grid-template-columns: repeat(2, minmax(0, 1fr));
	grid-gap: 1rem;
	grid-row: 1;
}

.usrt--content__card {
	background-color: #2D2D2D;
	border-radius: 6px;
	width: 100%;
	height: 55px;
	display: flex;
	align-items: center;
	padding: 0 12px;
}

.btn--upload-srt {
	color: #8C8C8C;
	font-size: 14px;
	font-weight: 600;
	gap: 8px;
}

.btn--delete-file {
	padding: 2px;
	transition: background-color 0.25s;
}

.btn--delete-file:hover {
	background-color: #383838;
}

.usrt--text {
	color: #fff;
	font-weight: 600;
}

.usrt--text__disable {
	color: #8C8C8C;
}

.dropdown-menu {
	min-width: 8rem;
	border-radius: 6px;
	background: #1F1F1F;
	box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.85);
}

.dropdown-menu-lang {
	width: 100%;
}

.select-lang {
  width: 100% !important;
  height: 12rem;
  padding: 10px 10px;
}

.select-lang::-webkit-scrollbar {
  width: 3px;
}

.select-lang::-webkit-scrollbar-track {
  background: #636363;
  border-radius: 4px;
}

.select-lang::-webkit-scrollbar-thumb {
  background: #B6B6B6;
  border-radius: 4px;
  background-clip: padding-box;
}

.select-lang::-webkit-scrollbar-thumb:hover {
  background: #888;
}

.btn-choose {
	font-size: 12px;
	color: #fff;
	font-weight: 400;
}

.btn--usrt-submit {
	background-color: #6D75F6;
	color: #fff;
	font-weight: 600;
	font-size: 16px;
	border-radius: 5px;
	padding: 10px 14px;
	width: 161px;

	transition: background-color 0.25s;
}

.btn--usrt-submit:not(:disabled):hover {
	background-color: #545EED;
}

.btn--usrt-submit:disabled {
	background-color: #2d2d2d;
	color: #8C8C8C;
}
</style>

<style>
.usrt__dialog {
  max-width: none;
}

.usrt {
  width: 667px;
  background-color: #1F1F1F;
  border-radius: 10px;
}
</style>