<template>
  <div>
    <EditorContent
      :editor="editor"
      spellcheck="false"
    />
    <BubbleSSMLOptionsMenu
      v-if="fullscreen && editor && !disableSsml"
      :editor="editor"
      @click:plain-text="(val) => $emit('click:plain-text', val)"
    />
  </div>
</template>

<script>
import { Editor, EditorContent } from '@tiptap/vue-2';
import StarterKit from '@tiptap/starter-kit';
import Placeholder from '@tiptap/extension-placeholder';

import DisableEnter from './extensions/DisableEnter';
import TextSelection from './extensions/TextSelection';
import FullscreenStorage from './extensions/FullscreenStorage';
import SSML from './extensions/SSML';
import BubbleSSMLOptionsMenu from './menus/SSMLOptionsMenu/BubbleMenu';

export default {
  components: {
    EditorContent,
    BubbleSSMLOptionsMenu,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: 'Input your text',
    },
    small: {
      type: Boolean,
      default: false,
    },
    fullscreen: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    disableSsml: {
      type: Boolean,
      default: false
    }
  },
  emits: ['input', 'focus', 'get-editor', 'generate-preview-ssml'],
  data() {
    return {
      editor: null,
    };
  },
  watch: {
    value(newValue) {
      if (this.parseValue(newValue) !== this.editor.getHTML()) {
        this.editor.commands.setContent(this.parseValue(newValue), false);
      }
    },
    readonly(newReadonly) {
      this.editor.setEditable(!newReadonly);
    },
    placeholder(newVal) {
      this.updatePlaceholder(newVal);
    }
  },
  methods: {
    parseValue(value) {
      let parsedValue = value;

      // Parse SSML with Pause
      parsedValue = parsedValue.replace(/<((?:fe-)?\w+)>([^<|>|/]+)<(?:fe-)?end><(\w+)>/gm, '<ssml type="$1" pause="$3">$2</ssml>');

      // Parse SSML without Pause
      parsedValue = parsedValue.replace(/<((?:fe-)?\w+)>([^<|>|/]+)<(?:fe-)?end>/gm, '<ssml type="$1">$2</ssml>');

      // Add Paragraph Tag
      parsedValue = `<p>${parsedValue}</p>`;

      return parsedValue;
    },
    renderValue(value) {
      let renderedValue = value;

      // Remove Paragraph Tag
      renderedValue = renderedValue.replace(/<p>|<\/p>/gmi, '');

      // Render SSML + fe Prefix with Pause
      renderedValue = renderedValue.replace(/<ssml type="fe-(\w+)" pause="(\w+)">([^<|>|/]+)<\/ssml>/gm, '<fe-$1>$3<fe-end><$2>');

      // Render SSML + fe Prefix without Pause
      renderedValue = renderedValue.replace(/<ssml type="fe-(\w+)">([^<|>|/]+)<\/ssml>/gm, '<fe-$1>$2<fe-end>');

      // Render SSML with Pause
      renderedValue = renderedValue.replace(/<ssml type="(\w+)" pause="(\w+)">([^<|>|/]+)<\/ssml>/gm, '<$1>$3<end><$2>');

      // Render SSML without Pause
      renderedValue = renderedValue.replace(/<ssml type="(\w+)">([^<|>|/]+)<\/ssml>/gm, '<$1>$2<end>');

      return renderedValue;
    },
    addPlaceholder() {
      const placeholderExtension = this.editor.extensionManager.extensions.find(
        (extension) => extension.name === 'placeholder'
      );

      if (placeholderExtension) {
        placeholderExtension.options.placeholder = 'Input your text'
      }
    },
    clearPlaceholder() {
      const placeholderExtension = this.editor.extensionManager.extensions.find(
        (extension) => extension.name === 'placeholder'
      );

      if (placeholderExtension) {
        placeholderExtension.options.placeholder = ''
      }
    },
    updatePlaceholder(newPlaceholder) {
      const placeholderExtension = this.editor.extensionManager.extensions.find(
        (extension) => extension.name === 'placeholder'
      );

      if (placeholderExtension) {
        placeholderExtension.options.placeholder = newPlaceholder;
      }
    },
  },
  mounted() {
    let extensions = [
      StarterKit.configure({
        blockquote: false,
        bulletList: false,
        codeBlock: false,
        hardBreak: false,
        heading: false,
        horizontalRule: false,
        listItem: false,
        orderedList: false,
        bold: false,
        code: false,
        italic: false,
        strike: false,
        dropcursor: false,
      }),
      Placeholder.configure({
        placeholder: this.placeholder,
      }),
      DisableEnter,
      TextSelection,
      SSML,
    ];

    if (this.fullscreen) {
      extensions.push(FullscreenStorage);
    }

    this.editor = new Editor({
      content: this.parseValue(this.value),
      editable: !this.readonly,
      extensions,
      editorProps: {
        attributes: {
          class: `tta-script-editor tta-script-editor--small`,
        },
      },
      onUpdate: () => {
        this.$emit('input', this.renderValue(this.editor.getHTML()));
      },
      onFocus: () => {
        // this.clearPlaceholder()
        this.$emit('focus');
      },
      onBlur: () => {
        // this.addPlaceholder();
        this.$emit('blur');
      },
    });

    if (this.fullscreen) {
      this.editor.storage.fullscreenStorage.generatePreviewSSML = (data) => this.$emit('generate-preview-ssml', data);
    }

    this.$emit('get-editor', this.editor);
  },
  beforeDestroy() {
    this.editor.destroy();
  },
};
</script>

<style>
.tta-script-editor {
  height: 100px;
  overflow-x: hidden;
  overflow-y: auto;
}

.tta-script-editor--small {
  height: auto;
}

.tta-script-editor--fullscreen {
  height: 20rem;
  font-size: 1.15rem;
}

.tta-script-editor > * {
  margin: 0;
}

.tta-script-editor > *::selection {
  background-color: #6D75F680;
  color: #FFFFFF;
}

.tta-script-editor p.is-editor-empty:first-child::before {
  content: attr(data-placeholder);
  float: left;
  height: 0;
  color: rgba(255, 255, 255, 0.3);
  pointer-events: none;
}
</style>
