<template>
	<b-modal :id="id" centered hide-header hide-footer dialog-class="adtc__dialog d-flex align-items-center justify-content-center" content-class="adtc" size="md" @hidden="handleHidden" @shown="shouldTranslate = true">
        <div class="d-flex justify-content-end">
            <button class="btn d-flex p-0 button--close-srt justify-content-center align-items-center pr-0" @click="$bvModal.hide(id)"><b-icon icon="x"></b-icon></button>
        </div>
		<div class="adtc--content d-flex flex-column container-fluid">
			<h5 class="adtc--content__title">
				Translation Correction
			</h5>
			<div class="adtc--content__list d-flex flex-column w-100 mt-3">
				<div class="adtc--content__card d-flex align-items-center justify-content-between">
					<p class="tc--text1 m-0">FROM</p>
					<div class="dropdown">
						<button
							:id="`${_uid}__choose-language-1`"
							ref='buttonLang1'
							class="btn btn-dropdown p-0 d-flex align-items-center w-100"
							type="button"
							data-toggle="dropdown"
							aria-haspopup="true"
							aria-expanded="false"
						>
							<img v-if="selectedLangSource?.flagSrc" :src="selectedLangSource?.flagSrc" class="img-fluid mr-2" style="width:15px; height: 15px;">
							<p class="adtc--text mb-0 mr-3">{{ selectedLangSource?.name }}</p>
							<div class="ml-auto">
								<svg xmlns="http://www.w3.org/2000/svg" width="10" height="6" viewBox="0 0 10 6" fill="none">
									<path d="M1 1L5 5L9 1" stroke="white"/>
								</svg>
							</div>
						</button>
						<div class="dropdown-menu dropdown-menu-lang dropdown-menu-right mt-2" :aria-labelledby="`${_uid}__choose-language-1`">
							<div class="px-1">
								<div class="select-lang overflow-auto px-1">
									<button class="btn btn-choose d-block w-100 text-left" @click="internalLangSource = 'detect'">
										<div class="d-flex align-items-center">
											<p class="ad-uc__drop-wrap--text m-0">✨ Auto Detect</p>
										</div>
									</button>
									<button v-for="country in sortedCountries" :key="country.code + country.name" class="btn btn-choose d-block w-100 text-left" @click="internalLangSource = country.id">
										<div class="d-flex align-items-center">
											<img
												:src="country.flagSrc"
												class="img-fluid"
												style="width: 18px; height: 18px"
											/>
											<p class="ad-uc__drop-wrap--text m-0 ml-2">{{ country.name }}</p>
											<img src="@/assets/images/icons/ic_news.svg" alt="" class="ml-2" v-if="country.name == 'Multilingual'">
										</div>
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
				<textarea class="adtc--text-area" :readonly="isTranslate" v-model="valuePlainText"></textarea>
				<div class="adtc--content__card d-flex align-items-center justify-content-between mt-2">
					<p class="tc--text1 m-0">TRANSLATE TO</p>
					<div class="dropdown">
						<button
							:id="`${_uid}__choose-language-2`"
							ref='buttonLang2'
							class="btn btn-dropdown p-0 d-flex align-items-center w-100"
							type="button"
							data-toggle="dropdown"
							aria-haspopup="true"
							aria-expanded="false"
						>
							<img :src="selectedLangTarget?.flagSrc" class="img-fluid mr-2" style="width:15px; height: 15px;">
							<p class="adtc--text mb-0 mr-3">{{ selectedLangTarget?.name }}</p>
							<div class="ml-auto">
								<svg xmlns="http://www.w3.org/2000/svg" width="10" height="6" viewBox="0 0 10 6" fill="none">
									<path d="M1 1L5 5L9 1" stroke="white"/>
								</svg>
							</div>
						</button>
						<div class="dropdown-menu dropdown-menu-lang dropdown-menu-right mt-2" :aria-labelledby="`${_uid}__choose-language-2`">
							<div class="px-1">
								<div class="select-lang overflow-auto px-1">
									<button v-for="country in sortedCountries" :key="country.code + country.name" class="btn btn-choose d-block w-100 text-left" @click="internalLangTarget = country.id">
										<div class="d-flex align-items-center">
											<img
												:src="country.flagSrc"
												class="img-fluid"
												style="width: 18px; height: 18px"
											/>
											<p class="ad-uc__drop-wrap--text m-0 ml-2">{{ country.name }}</p>
											<img src="@/assets/images/icons/ic_news.svg" alt="" class="ml-2" v-if="country.name == 'Multilingual'">
										</div>
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
				<textarea class="adtc--text-area" readonly v-model="afterTranslate"></textarea>
			</div>
			<div class="btn--adtc-howto p-0 ml-auto mt-1 d-flex align-items-center justify-content-center position-relative cursor-pointer">
				<img src="@/assets/images/icons/ic_qm-autodub.svg" alt="" class="mr-1">
				<span>How to</span>
				<div class="adtc-howto__wrapper position-absolute p-3">
					<ol class="adtc-howto_ol d-flex flex-column px-3">
						<li><span class="fw-600">Check Your Script :</span> Make sure the text in the "FROM" box is correct and represents what you want to translate.</li>
						<li><span class="fw-600">Choose Target Language :</span> Pick the language you want to translate to from the options in the "TRANSLATE TO" box.</li>
						<li><span class="fw-600">Translate :</span> Click the "Translate" button and wait for the system to generate the translation.</li>
						<li><span class="fw-600">Review and Apply :</span> Check the translated result. If it looks good, click "Apply" to use this translation in your dubbed video adaptation.</li>
						<li><span class="fw-600">Confirm and Adjust :</span> Review your adapted video to make sure the translation fits well. If needed, make any adjustments to ensure it's just right.</li>
					</ol>
					<p class="m-0 adtc-howto__p">By following these straightforward steps, you can effectively use the translation correction feature for your dubbed videos.</p>
				</div>
			</div>
			<button :disabled="isTranslate || internalLangSource == internalLangTarget" class="btn btn--adtc-submit mx-auto d-flex align-items-center justify-content-center" @click="shouldTranslate ? handleTranslate() : handleApply()">
				<template v-if="shouldTranslate">
					<img src="@/assets/images/icons/ic_translate-autodub.svg" alt="" class="mr-2">
					<span>{{ isTranslate ? 'Translating...' : 'Translate'}}</span>
				</template>
				<template v-else>
					<font-awesome-icon
                        icon="fa-solid fa-check"
						class="mr-2"
                    />
					<span>Apply</span>
				</template>

			</button>
		</div>
    </b-modal>
</template>

<script>
import { translateText } from '@/services/auto-dub/autodub.service';
import { AlertUtils } from "@/mixins/AlertUtils";

export default {
	mixins: [AlertUtils],
	props: {
		id: {
			type: String,
			default: 'ad-translate-corection-modal'
		},
		languages: {
			type: Array,
			default: () => {
				return []
			}
		},
		plainText: {
			type: String,
			default: null
		},
		langSource: {
			type: String,
			default: 'detect',
		},
		langTarget: {
			type: String,
			default: null
		}
	},
	computed: {
		sortedCountries() {
			if (this.languages.length > 0) {
				const sortedLanguages = [...this.languages];

				sortedLanguages.sort((a, b) => {
				const nameA = a.name.toLowerCase();
				const nameB = b.name.toLowerCase();

				if (nameA === 'multilingual') {
					return -1; // Pindahkan 'Multilingual' ke atas
				}

				if (nameB === 'multilingual') {
					return 1; // Pindahkan 'Multilingual' ke atas
				}

				if (nameA < nameB) {
					return -1;
				}

				if (nameA > nameB) {
					return 1;
				}

				return 0;
				});

				return sortedLanguages;
			} else {
				return this.languages;
			}
		},
		selectedLangSource() {
			if(this.internalLangSource != 'detect') {
				let dataLanguages = this.languages.find((item) => item.id == this.internalLangSource)
				return dataLanguages
			} else {
				let dataDetect = {
					id: 'detect',
					name: '✨ Auto Detect',
					flagSrc: null
				}
				return dataDetect
			}
		},
		selectedLangTarget() {
			let dataLanguages = this.languages.find((item) => item.id == this.internalLangTarget)
			return dataLanguages
		}
	},
	watch: {
		plainText: {
			handler(newPlainText) {
				this.valuePlainText = newPlainText
			},
			deep: true
		},
		valuePlainText: {
			handler(newValue) {
				if(newValue != this.translatedText) {
					this.shouldTranslate = true
				} else {
					this.shouldTranslate = false
				}
			}
		},
		translatedText: {
			handler(newValue) {
				if(newValue != this.valuePlainText) {
					this.shouldTranslate = true
				} else {
					this.shouldTranslate = false
				}
			}
		},
		internalLangSource: {
			handler(newValue) {
				if(newValue != this.generatedLangSource) {
					this.shouldTranslate = true
				} else {
					this.shouldTranslate = false
				}
			}
		},
		internalLangTarget: {
			handler(newValue) {
				if(newValue != this.generatedLangTarget) {
					this.shouldTranslate = true
				} else {
					this.shouldTranslate = false
				}
			}
		}
	},
	data() {
		return {
			valuePlainText : this.plainText,
			internalLangSource : this.langSource,
			internalLangTarget : this.langTarget,
			generatedLangSource : null,
			generatedLangTarget : null,
			afterTranslate : null,
			translatedText : null,
			isTranslate : false,
			shouldTranslate : true,
		}
	},
	methods: {
		handleApply() {
			this.$emit('click:replace-translate', this.afterTranslate)
			this.$bvModal.hide(this.id)
		},
		handleTranslate() {
			this.isTranslate = true

			let formData = {
				text : this.valuePlainText,
				lang_source : this.internalLangSource,
				lang_target : this.internalLangTarget
			}

			translateText(formData)
			.then((response) => {
				if(response.status == 200){
					this.translatedText = this.valuePlainText
					this.afterTranslate = response.data
					this.generatedLangSource = this.internalLangSource
					this.generatedLangTarget = this.internalLangTarget
					this.shouldTranslate = false
				} else {
					this.alertFail('Something went wrong')
				}
				this.isTranslate = false
			})
			.catch((error) => {
				console.error(error)
				this.alertFail('Something went wrong')
			})
		},
		handleHidden() {
			this.afterTranslate = null
			this.shouldTranslate = true
			this.$emit('click:reset-translate-correction')
		}
	}
}
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Fira+Code&display=swap");

.button--close-srt {
	width: 28px;
	height: 28px;
	font-size: 16px;
	border-radius: 50%;
	color: #000;
	background-color: #fff;
}

.adtc--content {
	gap: 9px;
	color: #fff;
}

.adtc--content__title {
	font-size: 20px;
	font-weight: 600;
}

.adtc--content__desc {
	font-size: 14px;
}

.adtc--content__list {
	gap: 8px;
}

.adtc--content__card {

}

.btn--upload-srt, .tc--text1 {
	color: #8C8C8C;
	font-size: 14px;
	font-weight: 600;
	gap: 8px;
}

.tc--text1 {
	font-weight: 400;
}

.btn--delete-file {
	padding: 2px;
	transition: background-color 0.25s;
}

.btn--delete-file:hover {
	background-color: #383838;
}

.adtc--text {
	color: #fff;
	font-weight: 400;
}

.adtc--text__disable {
	color: #8C8C8C;
}

.dropdown-menu {
	min-width: 8rem;
	border-radius: 6px;
	background: #1F1F1F;
	box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.85);
}

.dropdown-menu-lang {
	min-width: 12rem;
}

.select-lang {
  width: 100% !important;
  height: 12rem;
  padding: 10px 10px;
}

.select-lang::-webkit-scrollbar {
  width: 3px;
}

.select-lang::-webkit-scrollbar-track {
  background: #636363;
  border-radius: 4px;
}

.select-lang::-webkit-scrollbar-thumb {
  background: #B6B6B6;
  border-radius: 4px;
  background-clip: padding-box;
}

.select-lang::-webkit-scrollbar-thumb:hover {
  background: #888;
}

.btn-choose {
	font-size: 12px;
	color: #fff;
	font-weight: 400;
}

.btn--adtc-submit {
	background-color: #6D75F6;
	color: #fff;
	font-weight: 600;
	font-size: 16px;
	border-radius: 5px;
	padding: 10px 14px;
	width: 161px;

	transition: background-color 0.25s;
}

.btn--adtc-submit:not(:disabled):hover {
	background-color: #545EED;
}

.btn--adtc-submit:disabled {
	background-color: #2d2d2d;
	color: #8C8C8C;
}

.adtc--text-area {
	background-color: #2d2d2d;
	color: #FFF;
	font-family: Fira Code;
	font-size: 14px;
	font-weight: 400;
	height: 89px;
	resize: none;
	padding: 10px 14px;
	border: none;
	border-radius: 8px;
}

.btn--adtc-howto {
	color: #FFF;
	font-family: Avenir Next;
	font-size: 12px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
}

.adtc-howto__wrapper {
	left: 70px;
	width: 350px;
	border-radius: 6px;
	background-color: #1F1F1F;
	box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.30);

	visibility: hidden;
	opacity: 0;
	transition: opacity 0.25s;
}

.btn--adtc-howto:hover .adtc-howto__wrapper {
	visibility: visible;
	opacity: 1;
}

.adtc-howto__wrapper ol li, .adtc-howto__p {
	font-weight: 400;
	font-size: 10px;
}

.adtc-howto_ol {
	gap: 8px;
}

.fw-600 {
	font-weight: 600;
}
</style>

<style>
.adtc__dialog {
  max-width: none;
}

.adtc {
  width: 667px;
  background-color: #1F1F1F;
  border-radius: 10px;
}
</style>