<template>
    <b-modal id="video-duration-exceeds" centered header-class="border-0 pt-4 pb-0" :hide-footer="true" :hide-header="true" footer-class="border-0 pb-3 pt-0 mx-auto" size="sm">
        <div class="float-right">
            <button class="btn d-flex justify-content-center align-items-center pr-0" @click="$bvModal.hide('video-duration-exceeds')"><img src="@/assets/images/icons/ic_close.svg" alt=""></button>
        </div>
        <div class="mt-5 text-center text-white mb-3">
            <img src="@/assets/images/icons/modal_warning.svg" alt="Video Duration Exceeds" class="img-fluid">
            <div class="mt-3">
                <h4 v-if="type == 'infinity'" class="font-weight-bold mb-2">Incompatible File</h4>
                <h4 v-else class="font-weight-bold mb-2">{{ type == 'video' ? 'Video' : 'Audio' }} Duration Exceeds</h4>
                <h5 v-if="type == 'infinity'" class="font-weight-normal mt-3">We're unable to complete the upload. Please try again with another file extension.</h5>
                <h5 v-else class="font-weight-normal mt-3">The uploaded {{ type == 'video' ? 'video' : 'audio' }} is longer than <br> the allowed 3-minute duration.</h5>
            </div>
            <div class="d-flex justify-content-center mt-3">
                <button class="btn btn-purple" @click="$bvModal.hide('video-duration-exceeds')"><p class="mx-2 mb-0 font-weight-bold">Ok</p></button>
            </div>
        </div>
    </b-modal>
</template>

<script>
export default {
    props: {
        type: {
            type: String,
            default: 'video'
        }
    },
}
</script>

<style scoped>
.btn-purple {
    background-color: #6D75F6;
    color: white;
    box-shadow: none !important;
    border: none;
	transition: background-color 0.25s;
}

.btn-purple:hover {
	background-color: #545EED;
}
</style>

<style>
@font-face {
  font-family: "Avenir Next";
  src: url("../../assets/font/AvenirNextLTPro-Regular.otf");
}
@font-face {
  font-family: "Avenir Next";
  src: url("../../assets/font/AvenirNextLTPro-Bold.otf");
  font-weight: 900;
}
@font-face {
  font-family: "Avenir Next";
  src: url("../../assets/font/AvenirNext-DemiBold.ttf");
  font-weight: bold;
}
@import url("https://fonts.googleapis.com/css2?family=Muli:wght@300;400;500;600;700;800&display=swap");

#video-duration-exceeds .modal-content{
    background-color: #222222;
     font-family: "Avenir Next", Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


#video-duration-exceeds .modal-content{
    border-radius: 10px !important;
    background-color: #1F1F1F;
}

#video-duration-exceeds .modal-content{
    border-radius: 10px !important;
    background-color: #1F1F1F;
}

button {
    box-shadow: none !important;
}
</style>