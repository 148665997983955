<template>
	<div class="auto-dubbing d-flex justify-content-center container-fluid">
		<div class="auto-dubbing__inner d-flex flex-column w-100">
			<div class="d-flex align-items-center mt-3">
				<h3 class="auto-dubbing__title">Auto Dubbing</h3>
				<p class="auto-dubbing__beta mb-0 ml-2">BETA</p>
			</div>
			<p class="auto-dubbing__subtitle mb-3">{{ $t('auto-dubbing--note') }}</p>
			<main class="auto-dubbing__main">
				<div class="auto-dubbing__middle d-flex h-100 pb-3">
					<template v-if="scripts.length == 0">
						<div class="auto-dubbing__left-content d-flex flex-column">
							<p class="auto-dubbing__label mb-0">{{ $t('upload-video-or') }}</p>
							<ADUploadCard :queue="totalQueueAutodub" :languages="languages" :open-lang="openLang" v-model="valueCardVideo" :list-preview="listVideoPreview" @click:play-preview="handleClickPlayPreview" :is-submit="isSubmit" @duration:type="(val) => typeFileUploaded = val"/>
							<div class="d-flex align-items-center justify-content-center">
								<button :disabled="isSubmit" class="btn btn--reset px-4" @click="resetValue">{{ $t('reset') }}</button>
								<button :disabled="!valueCardVideo.fileVideo.file || isSubmit" class="btn button--submit-ad" @click="handleGetScript">{{ $t('submit') }}</button>
							</div>
						</div>
						<div class="auto-dubbing__right-content">
							<ADProgress :is-submit="isSubmit && !valueCardVideo.additionalInclude" :progress="progressGetScript" :allocation="allocation"/>
						</div>
					</template>
					<template v-else>
						<div class="auto-dubbing__final-content d-flex flex-column w-100">
							<p class="auto-dubbing__label mb-0">{{ $t('transvription-preview--note') }}</p>
							<ADTable :languages="languages" v-model="scripts"/>
							<button :disabled="hasEdits || scripts.length == 0 || isGenerate" class="btn button--submit-ad mx-auto mt-2" @click="handleGenerateScript">{{ isGenerate ? 'Generating...' : 'Generate' }}</button>
						</div>
					</template>
				</div>
			</main>
		</div>
		<ADUploadSrtModal id="upload-srt-modal" :languages="languages" @click:upload-srt="handleUploadSrt"/>
		<ADFeedbackModal id="test-feedback-ad"/>
		<ADPlayPreviewModal id="ad-play-preview" :url="previewUrl"/>
		<ADPreviewModal id="show-preview-modal" :src="finalPath"/>
		<ServerErrorModal />
		<ADLangSameDetected @change:open-lang="handleOpenLang"/>
		<SocaLoading v-if="isSaving || (valueCardVideo.additionalInclude && isSubmit) || isGenerate"/>
		<ADVideoDurationExceeds :type="typeFileUploaded"/>
		<ADNoAudioDetected />
		<InsuficientQuotaADModal />
	</div>
</template>

<script>
import ADUploadCard from "@/components/auto-dubbing/upload-card"
import ADProgress from "@/components/auto-dubbing/progress"
import ADTable from "@/components/auto-dubbing/table"
import ADUploadSrtModal from "@/components/auto-dubbing/modal/UploadSrt.vue"
import ADPlayPreviewModal from "@/components/modal/YoutubePlayer.vue"

import ADFeedbackModal from "@/components/auto-dubbing/modal/Feedback.vue"
import ADPreviewModal from "@/components/auto-dubbing/modal/PreviewAutoDub.vue"
import ADLangSameDetected from "@/components/modal/TargetLangSame.vue"
import ADVideoDurationExceeds from "@/components/modal/VideoDurationExceeds.vue"
import ADNoAudioDetected from "@/components/modal/NoAudioDetected.vue"

import SocaLoading from '@/components/loading/Soca'

import ServerErrorModal from '@/components/modal/ServerError';
import InsuficientQuotaADModal from '@/components/modal/InsuficientQuoatAD';
// import axios from 'axios';

import { base_url_machine_learning, base_url_auto_dubbing, base_url_asset } from '@/config/base_url';
import { getMasterLanguagesAutodub } from '@/services/master-languages/master_languages.service';
import { generateScriptAutodub, postAutodub, uploadFileByChunks, getScriptAutodub, getQueueAutodub, getStreamObj } from '@/services/auto-dub/autodub.service';
import { getJobStatus } from '@/services/generative-ai-text-to-audio/generative-ai-text-to-audio.service';
import { AlertUtils } from "@/mixins/AlertUtils";
import { subscriptionsStatus } from '@/services/subscriptions/subscriptions.service'

import $ from "jquery"

export default {
	mixins: [AlertUtils],
	components: {
		ADUploadCard,
		ADUploadSrtModal,
		ADProgress,
		ADTable,
		ADFeedbackModal,
		ADPlayPreviewModal,
		ADPreviewModal,
		ServerErrorModal,
		ADLangSameDetected,
		ADVideoDurationExceeds,
		ADNoAudioDetected,
		InsuficientQuotaADModal,
		SocaLoading
	},
	data() {
		return {
			languages: [],
			loadingLanguages: false,
			isSubmit: false,
			isGenerate: false,
			isFinal: true,
			isSaving: false,

			valueCardVideo: {
				fileVideo: {
					file: null,
					src: null,
					name: null,
					type: null,
					thumbnail: null,
					fileSize: null,
				},
				additionalInclude: false,
				speaker: 1,
				targetLang: {
					id: '6401ae940eb4d9111ec260d7',
					name: 'Indonesia',
					code: 'ID',
					flagSrc: require('@/assets/images/icons/ic_flag_indonesia.svg'),
				},
				fileSrt1: {
					file: null,
					fileName: null,
					lang: null
				},
				fileSrt2: {
					file: null,
					fileName: null,
					lang: null
				},
			},

			selectedLanguages: {
				id: '6401ae940eb4d9111ec260d7',
				name: 'Indonesia',
				code: 'ID',
				flagSrc: require('@/assets/images/icons/ic_flag_indonesia.svg')
			},

			listVideoPreview: [
				{
					thumbnail: require('@/assets/images/AADC.png'),
					url: base_url_asset + 'auto-dubb-example/video/aadc-video-example.m4v',
				},
				{
					thumbnail: require('@/assets/images/JackieChan.jpeg'),
					url: base_url_asset + 'auto-dubb-example/video/jackie-chan-video-example.m4v'
				},
				{
					thumbnail: require('@/assets/images/SusterNgesot.png'),
					url: base_url_asset + 'auto-dubb-example/video/suster-ngesot-video-example.m4v'
				},
			],

			previewUrl: null,
			intervalFinal: null,
			jobId: null,
			courseId: null,
			progressGetScript: 0,

			scripts: [],

			intervalFinal2: null,
			finalPath: null,
			allocation: {},

			openLang: false,

			typeFileUploaded: 'video',

			totalQueueAutodub: 0,
		}
	},
	async mounted() {
		$("div.auto-dubbing").parent().addClass('pb-0');
		await this.handleGetDataFromStorage()
		this.getAllocation()
		this.populateLanguages()
		this.getQueueAutodub()
		if(this.jobId && this.scripts.length == 0) {
			this.getJobStatusIfReload()
		}
	},
	watch: {
		valueCardVideo: {
			handler(newValue) {
				if(newValue.fileVideo.name) {
					sessionStorage.setItem('auto-dub::video-name', newValue.fileVideo.name);
				}

				if(newValue.fileVideo.fileSize) {
					sessionStorage.setItem('auto-dub::file-size', newValue.fileVideo.fileSize);
				}

				if(newValue.fileVideo.thumbnail) {
					sessionStorage.setItem('auto-dub::thumbnail', newValue.fileVideo.thumbnail);
				}
			},
			deep: true,
		},
		scripts: {
			handler(newValue) {
				if(newValue) {
					sessionStorage.setItem('auto-dub::scripts', JSON.stringify(newValue));
				}
			},
			deep: true,
		},
		jobId: {
			handler(newValue) {
				if(newValue) {
					sessionStorage.setItem('auto-dub::job-id', newValue)
				}
			},
			deep: true
		},
		courseId: {
			handler(newValue) {
				if(newValue) {
					sessionStorage.setItem('auto-dub::course-id', newValue)
				}
			},
			deep: true
		}
	},
	computed: {
		hasEdits() {
			return this.scripts.some(item => item.isEdit === true);
		},
	},
	methods: {
		async populateLanguages() {
			this.loadingLanguages = true;

			try {
				const { data: languages } = await getMasterLanguagesAutodub();

				this.languages = [];

				for (const language of languages) {
					this.languages.push({
						name: language.DisplayName,
						code: language.LanguageCode.toUpperCase(),
						flagSrc: base_url_machine_learning + language.Flags,
						id: language._id.$oid
					});
				}
			} catch (error) {
				console.error(error);
			}

			this.loadingLanguages = false;
		},
		resetValue() {
			this.valueCardVideo =  {
				fileVideo: {
					file: null,
					src: null,
					name: null,
					type: null,
					thumbnail: null,
					fileSize: null,
				},
				additionalInclude: false,
				speaker: 1,
				targetLang: {
					id: '6401ae940eb4d9111ec260d7',
					name: 'Indonesia',
					code: 'ID',
					flagSrc: require('@/assets/images/icons/ic_flag_indonesia.svg'),
				},
				fileSrt1: {
					file: null,
					fileName: null,
					lang: null
				},
				fileSrt2: {
					file: null,
					fileName: null,
					lang: null
				}
			}
			this.openLang = false
		},
		handleUploadSrt(val) {
			this.valueCardVideo.fileSrt1.file = val.file1.file || null
			this.valueCardVideo.fileSrt1.fileName = val.file1.name || null
			this.valueCardVideo.fileSrt2.file = val.file2?.file || null
			this.valueCardVideo.fileSrt2.fileName = val.file2?.name || null
			this.$bvModal.hide('upload-srt-modal')
		},
		async handleGetDataFromStorage() {
			if(sessionStorage.getItem('auto-dub::uploaded-card-value')) {
				this.valueCardVideo = await JSON.parse(sessionStorage.getItem('auto-dub::uploaded-card-value'))
			}

			if(sessionStorage.getItem('auto-dub::scripts')) {
				this.scripts = await JSON.parse(sessionStorage.getItem("auto-dub::scripts"))
			}

			if(sessionStorage.getItem('auto-dub::job-id')) {
				this.jobId = sessionStorage.getItem("auto-dub::job-id")
			}

			if(sessionStorage.getItem('auto-dub::course-id')) {
				this.courseId = sessionStorage.getItem("auto-dub::course-id")
			}

			if(sessionStorage.getItem('auto-dub::video-name')) {
				this.valueCardVideo.fileVideo.name = sessionStorage.getItem('auto-dub::video-name')
			}

			if(sessionStorage.getItem('auto-dub::file-size')) {
				this.valueCardVideo.fileVideo.fileSize = sessionStorage.getItem('auto-dub::file-size')
			}

			if(sessionStorage.getItem('auto-dub::thumbnail')) {
				this.valueCardVideo.fileVideo.thumbnail = sessionStorage.getItem('auto-dub::thumbnail')
			}
		},
		removeDataFromStorage() {
			sessionStorage.removeItem('auto-dub::uploaded-card-value')
			sessionStorage.removeItem('auto-dub::scripts')
			sessionStorage.removeItem('auto-dub::job-id')
			sessionStorage.removeItem('auto-dub::course-id')
			sessionStorage.removeItem('auto-dub::video-name')
			sessionStorage.removeItem('auto-dub::file-size')
			sessionStorage.removeItem('auto-dub::thumbnail')
		},
		handleClickPlayPreview(url) {
			this.previewUrl = url
			if(this.previewUrl) {
				this.$bvModal.show('ad-play-preview')
			}
		},
		async handleGetScript() {
			this.isSubmit = true

			let index = 1
			let course_id = null
			if(this.valueCardVideo.fileVideo.type == 'video') {
				if(this.valueCardVideo.fileVideo.file && this.valueCardVideo.fileVideo.file.length > 0) {
					for(const chunk of this.valueCardVideo.fileVideo.file) {

						let chunksData = new FormData();

						chunksData.append('course_id', course_id || '');
						chunksData.append('file_name', this.valueCardVideo.fileVideo.name);
						chunksData.append('chunk_number', index.toString());
						chunksData.append('total_chunks', this.valueCardVideo.fileVideo.file.length || 0);
						chunksData.append('file_size', chunk.size);
						chunksData.append('file_chunk', chunk);

						await uploadFileByChunks(chunksData)
						.then((response) => {
							if(response.status == 200){
								if(!course_id) {
									course_id = response.data.course_id
								}
								if(response.data.path) {
									let formData = new FormData();
									formData.append('path_video', response.data.path);
									formData.append('generate', this.valueCardVideo.additionalInclude);
									formData.append('course_id', response.data.course_id);
									formData.append('num_speaker', this.valueCardVideo.speaker);
									formData.append('target_lang', this.valueCardVideo.targetLang.id);

									getScriptAutodub(formData)
									.then((response) => {
										if(response.status == 200) {
											let jobId = response.data.job_id
											this.valueCardVideo.fileVideo.thumbnail = response.data.all_slide.path_thumbnail && response.data.all_slide.path_thumbnail != 'stg/None' ? base_url_auto_dubbing + 'autodub/stream_obj?path=' +  response.data.all_slide.path_thumbnail : null
											if(this.valueCardVideo.additionalInclude) {
												this.jobId = jobId
												this.postToProjects()
											} else {
												if(jobId) {
													this.jobId = jobId
													this.intervalFinal = setInterval(() => {
														getJobStatus(jobId)
														.then((res) => {
															if(res.status == 200) {
																this.getQueueAutodub()
																this.progressGetScript = parseInt(res.data.progress)
																if(res.data.message == 'Target Lang Same with Detected Lang') {
																	this.$bvModal.show('target-lang-same-modal')
																	clearInterval(this.intervalFinal)
																	this.resetValue()
																	this.removeDataFromStorage()
																	this.isSubmit = false
																	this.progressGetScript = 0
																	this.openLang = false
																}
																if(res.data.status === "finish_script") {
																	this.jobId = jobId
																	this.courseId = res.data.course_id
																	// let script = base_url_auto_dubbing + res.data.script_path.replace('/src', '')
																	this.getStreamObjAD(res.data.script_path, 'json')
																	this.getAllocation()
																	clearInterval(this.intervalFinal)
																}
															} else if(res.status === 402 || res.status === 500) {
																this.$bvModal.show('server-error-modal');
																this.isSubmit = false
															}
														})
														.catch((error) => {
															this.isSubmit = false
															console.error(error)
															this.$bvModal.show('server-error-modal');
														})
													}, 5000)
												}
											}
										} else if(response.status == 403) {
											this.isSubmit = false
											this.$bvModal.show('insuficient-qad');
										} else if(response.status === 402 || response.status === 500) {
											this.isSubmit = false
											this.$bvModal.show('server-error-modal');
										} else if (response.status == 405) {
											this.isSubmit = false
											this.alertFail(response.data)
										} else if (response.response.status == 405 && response.response.data.data == 'File Too Large') {
											this.isSubmit = false
											this.alertFail('File size must less than 50MB')
											this.resetValue()
										} else if (response.response.status == 405) {
											this.isSubmit = false
											this.alertFail(response.response.data.data)
										} else if (response.response.status == 403) {
											this.isSubmit = false
											this.$bvModal.show('insuficient-qad');
										} else if(response.response.status == 500) {
											this.isSubmit = false
											this.$bvModal.show('server-error-modal');
										} else {
											this.isSubmit = false
										}
									})
									.catch((error) => {
										this.isSubmit = false
										this.$bvModal.show('server-error-modal');
										console.error(error)
									})
								}
							} else if (response.status == 403) {
								this.isSubmit = false
								this.$bvModal.show('insuficient-qad');
							} else if(response.response.status == 405 && response.response.data.message == "Audio not found") {
								this.$bvModal.show('no-audio-detected-modal')
								this.isSubmit = false
								this.resetValue()
							} else if (response.response.status == 405 && response.response.data.data == 'File Too Large') {
								this.isSubmit = false
								this.alertFail('File size must less than 50MB')
								this.resetValue()
							} else if (response.response.status == 405) {
								this.isSubmit = false
								this.alertFail(response.response.data.data)
							} else {
								this.isSubmit = false
							}
						})
						index++
					}
				} else {
					this.alertFail("Seems you haven't uploaded the file yet")
				}
			} else {
				let formData = new FormData();
				formData.append('file', this.valueCardVideo.fileVideo.file);
				formData.append('generate', this.valueCardVideo.additionalInclude);
				formData.append('course_id', null);
				formData.append('num_speaker', this.valueCardVideo.speaker);
				formData.append('target_lang', this.valueCardVideo.targetLang.id);

				getScriptAutodub(formData)
				.then((response) => {
					if(response.status == 200) {
						let jobId = response.data.job_id
						this.valueCardVideo.fileVideo.thumbnail = response.data.all_slide.path_thumbnail && response.data.all_slide.path_thumbnail != 'stg/None' ? base_url_auto_dubbing + 'autodub/stream_obj?path=' +  response.data.all_slide.path_thumbnail : null
						if(this.valueCardVideo.additionalInclude) {
							this.jobId = jobId
							this.postToProjects()
						} else {
							if(jobId) {
								this.jobId = jobId
								this.intervalFinal = setInterval(() => {
									getJobStatus(jobId)
									.then((res) => {
										if(res.status == 200) {
											this.getQueueAutodub()
											this.progressGetScript = parseInt(res.data.progress)
											if(res.data.message == 'Target Lang Same with Detected Lang') {
												this.$bvModal.show('target-lang-same-modal')
												clearInterval(this.intervalFinal)
												this.resetValue()
												this.removeDataFromStorage()
												this.isSubmit = false
												this.progressGetScript = 0
												this.openLang = false
											}
											if(res.data.status === "finish_script") {
												this.jobId = jobId
												this.courseId = res.data.course_id
												// let script = base_url_auto_dubbing + res.data.script_path.replace('/src', '')
												this.getStreamObjAD(res.data.script_path, 'json')
												this.getAllocation()
												clearInterval(this.intervalFinal)
											}
										} else if(res.status === 402 || res.status === 500) {
											this.$bvModal.show('server-error-modal');
											this.isSubmit = false
										}
									})
									.catch((error) => {
										this.isSubmit = false
										console.error(error)
										this.$bvModal.show('server-error-modal');
									})
								}, 5000)
							}
						}
					} else if (response.status == 403) {
						this.isSubmit = false
						this.$bvModal.show('insuficient-qad');
					} else if(response.status === 402 || response.status === 500) {
						this.isSubmit = false
						this.$bvModal.show('server-error-modal');
					} else if (response.status == 405) {
						this.isSubmit = false
						this.alertFail(response.data)
					} else if (response.response.status == 405 && response.response.data.data == 'File Too Large') {
						this.isSubmit = false
						this.alertFail('File size must less than 50MB')
						this.resetValue()
					} else if (response.response.status == 405) {
						this.isSubmit = false
						this.alertFail(response.response.data.data)
					} else if (response.response.status == 403) {
						this.isSubmit = false
						this.$bvModal.show('insuficient-qad');
					} else if(response.response.status == 500) {
						this.isSubmit = false
						this.$bvModal.show('server-error-modal');
					} else {
						this.isSubmit = false
					}
				})
				.catch((error) => {
					this.isSubmit = false
					this.$bvModal.show('server-error-modal');
					console.error(error)
				})
			}

		},
		getStreamObjAD(jsonURL, type) {
			getStreamObj(jsonURL)
			.then((response) => {
				if(type == 'json') {
					let data = response.data
					let facePath = response.face_path
					data.forEach((item) => {
						this.scripts.push({
							id: item.id,
							lang: item.lang,
							text: item.text,
							translate: item.translate,
							start: item.start,
							end: item.end,
							speaker: item.speaker,
							img: base_url_auto_dubbing + facePath[item['speaker'].split('_')[1]-1].replace('/src', ''),
							isEdit: false,
							lang_source: 'detect',
							lang_target: this.valueCardVideo.targetLang.id
						})
					})
					this.isSubmit = false
				} else if(type == 'image') {
					console.log(response)
				}
			})
			.catch(error => {
				console.error('Gagal mengambil data JSON:', error);
			});
		},
		handleGenerateScript() {
			this.isGenerate = true
			const jsonData = this.scripts;
			const jsonContent = JSON.stringify(jsonData);

			const blob = new Blob([jsonContent], { type: 'application/json' });
			const formData = new FormData();

			formData.append('script', blob, 'data.json');
			formData.append('job_id', this.jobId);
			formData.append('course_id', this.courseId);
			generateScriptAutodub(formData)
			.then((response) => {
				if(response.status == 200) {
					this.postToProjects()
				} else if (response.status == 403) {
					this.isSubmit = false
					this.$bvModal.show('insuficient-qad');
				} else if (response.response.status == 403) {
					this.isSubmit = false
					this.$bvModal.show('insuficient-qad');
				} else {
					this.$bvModal.show('server-error-modal');
				}
				this.isGenerate = false
			})
			.catch((error) => {
				this.$bvModal.show('server-error-modal');
				this.isGenerate = false
				console.error(error)
			})
		},
		handleGenerateScript2() {
			this.isGenerate = true
			const jsonData = this.scripts;
			const jsonContent = JSON.stringify(jsonData);

			const blob = new Blob([jsonContent], { type: 'application/json' });
			const formData = new FormData();

			formData.append('script', blob, 'data.json');
			formData.append('job_id', this.jobId);
			formData.append('course_id', this.courseId);
			generateScriptAutodub(formData)
			.then((response) => {
				if(response.status == 200) {
					this.intervalFinal2 = setInterval(() => {
								getJobStatus(response.data.job_id)
								.then((res) => {
									if(res.status == 200) {
										this.progressGetScript = parseInt(res.data.progress)
										if(res.data.status === "finish") {
											let path = base_url_auto_dubbing + res.data.final_path.replace('/src', '')
											this.finalPath = path
											this.$bvModal.show('show-preview-modal')
											clearInterval(this.intervalFinal2)
											this.isGenerate = false
										}
									} else if(res.status === 402 || res.status === 500) {
										this.$bvModal.show('server-error-modal');
										this.isGenerate = false
									}
								})
								.catch((error) => {
									this.isGenerate = false
									console.error(error)
									this.$bvModal.show('server-error-modal');
								})
							}, 5000)
				}
			})
			.catch((error) => {
				this.isGenerate = false
				console.error(error)
			})
		},
		getJobStatusIfReload() {
			this.isSubmit = true
			this.intervalFinal = setInterval(() => {
				getJobStatus(this.jobId)
				.then((res) => {
					if(res.status == 200) {
						this.progressGetScript = parseInt(res.data.progress)
						if(res.data.message == 'Target Lang Same with Detected Lang') {
							this.$bvModal.show('target-lang-same-modal')
							clearInterval(this.intervalFinal)
							this.resetValue()
							this.removeDataFromStorage()
							this.isSubmit = false
							this.progressGetScript = 0
							this.openLang = false
						}
						if(res.data.status === "finish_script") {
							this.courseId = res.data.course_id
							// let script = base_url_auto_dubbing + res.data.script_path.replace('/src', '')
							this.getStreamObjAD(res.data.script_path, 'json')
							clearInterval(this.intervalFinal)
						}
					} else if(res.status === 402 || res.status === 500) {
						this.$bvModal.show('server-error-modal');
						this.isSubmit = false
					}
				})
				.catch((error) => {
					this.isSubmit = false
					console.error(error)
					this.$bvModal.show('server-error-modal');
				})
			}, 5000)
		},
		postToProjects() {
			this.isSaving = true;
			let formData = {
				job_id: this.jobId || null,
				course_id: this.courseId || null,
				image_thumbnail: null, // Default: null
				content_name: this.valueCardVideo.fileVideo.name || 'Auto Dubbing',
				content_file_size: parseInt(this.valueCardVideo.fileVideo.fileSize) || 0,
				content_file: null,
			};

			if (this.valueCardVideo.fileVideo.thumbnail) {
				fetch(this.valueCardVideo.fileVideo.thumbnail)
				.then((response) => response.blob())
				.then((blob) => {
					const reader = new FileReader();
					reader.onload = () => {
					formData.image_thumbnail = reader.result; // Setelah gambar dalam format base64 tersedia
					this.isSaving = true;

					postAutodub(formData)
						.then((response) => {
							if (response.status) {
								this.removeDataFromStorage();
								this.$router.push({ name: 'My-Quiz', query: { folder_name: 'Dubbs' } });
							} else {
								console.log(response);
							}
							this.isSaving = false;
						})
						.catch((error) => {
							this.isSaving = false;
							console.error(error);
						});
					};
					reader.readAsDataURL(blob);
				})
				.catch((error) => {
					this.isSaving = false;
					console.error(error);
				});
			} else {
				postAutodub(formData)
				.then((response) => {
					if (response.status) {
						this.removeDataFromStorage()
						this.$router.push({ name: 'My-Quiz', query: { folder_name: 'Dubbs' } });
					} else {
						console.log(response);
					}
					this.isSaving = false;
				})
				.catch((error) => {
					this.isSaving = false;
					console.error(error);
				});
			}
		},
		handleOpenLang() {
			this.$bvModal.hide('target-lang-same-modal')
			this.openLang = true
		},
		async getAllocation() {
			await subscriptionsStatus()
			.then((res) => {
				if(res.status) {
					this.allocation = res.data
				}
			})
		},
		getQueueAutodub() {
            getQueueAutodub()
            .then((response) => {
                if(response.status == 200) {
                    this.totalQueueAutodub = response.data
                }
            })
        }
	},
	beforeDestroy() {
		if(this.intervalFinal) {
			clearInterval(this.intervalFinal)
		}
	}
}
</script>

<style scoped>
.auto-dubbing {
	min-height: calc(100vh - 80px);
	color: #fff;
	text-align: left;
	gap: 8px;
}

.auto-dubbing__inner {
	gap: 8px;
}

.auto-dubbing__title {
	color: #FFF;
	font-size: 24px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
}

.auto-dubbing__subtitle {
	color: #FFF;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

.auto-dubbing__beta {
	color: #8C8C8C;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

.auto-dubbing__main {
	display: contents;
}

.auto-dubbing__middle {
	gap: 12px;
	min-height: 0;
}

.auto-dubbing__left-content {
	width: 450px;
	gap: 12px;
}

.auto-dubbing__right-content {
	width: 100%;
	gap: 12px;
}

.auto-dubbing__final-content {
	gap: 12px;
}

.auto-dubbing__label {
	color: #FFF;
	font-size: 18px;
	font-weight: 500;
}

.button--submit-ad {
	background-color: #6D75F6;
	color: #fff;
	font-weight: 600;
	font-size: 16px;
	border-radius: 5px;
	padding: 10px 14px;
	width: 207px;

	transition: background-color 0.25s;
}

.button--submit-ad:not(:disabled):hover {
	background-color: #545EED;
}

.button--submit-ad:disabled {
	background-color: #2d2d2d;
	color: #8C8C8C;
}

.btn--reset {
	color: #FFF;
	font-size: 16px;
	font-weight: 600;
}
</style>