<template>
	<div class="ad-uc">
		<div class="ad-uc__inner d-flex flex-column position-relative">
			<div class="ad-uc__icon-info position-absolute">
				<img
					:src="require('@/assets/images/icons/ic_subscription_info.svg')"
					class=""
					alt=""
					height="15"
				/>
				<div class="ad-uc__info--inner position-absolute p-3">
					<p class="ad-uc__info--title mb-1">{{ $t('video-audio-requirements') }} :</p>
					<ul class="px-3 mb-0">
						<li v-html="$t('video-audio-requirements-li1')"></li>
						<li v-html="$t('video-audio-requirements-li2')"></li>
						<li v-html="$t('video-audio-requirements-li3')"></li>
						<li v-html="$t('video-audio-requirements-li4')"></li>
					</ul>
				</div>
			</div>

			<div class="cxad-uc__inner--padding" @dragover.prevent @dragenter.prevent @dragleave.prevent @drop.prevent>
				<label v-if="!formData.fileVideo.src" @drop="handleDrag($event)" for="uploadVideo" class="ad-uc__label py-3 m-0 d-flex flex-column align-items-center justify-content-center w-100 cxad-uc__inner--padding">
					<svg xmlns="http://www.w3.org/2000/svg" width="31" height="23" viewBox="0 0 31 23" fill="none">
						<rect x="2" y="2" width="27" height="19.6356" rx="2.4545" stroke="#6D75F6" stroke-width="2.05711"/>
						<path d="M2 4.4545C2 3.09892 3.09892 2 4.4545 2H26.5455C27.9011 2 29 3.09892 29 4.4545V6.90891H2V4.4545Z" stroke="#6D75F6" stroke-width="2.05711"/>
						<path d="M17.9582 13.4242C18.2855 13.6132 18.2855 14.0855 17.9582 14.2745L13.9082 16.6126C13.581 16.8016 13.1719 16.5654 13.1719 16.1875L13.1719 11.5112C13.1719 11.1333 13.581 10.8971 13.9082 11.086L17.9582 13.4242Z" fill="#6D75F6"/>
						<path d="M5.67969 6.90891L10.5888 2M13.0433 6.90891L17.9524 2M20.407 6.90891L25.316 2" stroke="#6D75F6" stroke-width="2.05711"/>
					</svg>
					<div class="d-flex flex-column">
						<p class="ad-uc__label-text1 m-0">{{ $t('choose-file-drag') }}</p>
						<p class="ad-uc__label-text2 m-0">Format file : .mp4, .mp3, .webm, .wav</p>
					</div>
					<p class="ad-uc__label-text3 m-0">{{ $t('the-video-duration') }}</p>
				</label>
				<div v-else class="video__wrapper w-100 d-flex align-items-center justify-content-center">
					<video v-if="formData.fileVideo.type == 'video'" controls :src="formData.fileVideo.src" class="video__wrapper--video"></video>
					<audio v-else controls :src="formData.fileVideo.src"></audio>
				</div>
			</div>

			<div class="cxad-uc__inner--padding">
				<div class="ad-uc__drop-wrap py-2 px-3 d-flex align-items-center justify-content-between w-100">
					<p class="ad-uc__drop-wrap--text m-0">{{ $t('how-many-speaker') }}</p>
					<div class="dropdown">
						<button
							:id="`${_uid}__choose-speaker`"
							ref='buttonspeaker'
							class="btn btn-dropdown p-0 d-flex align-items-center"
							type="button"
							data-toggle="dropdown"
							aria-haspopup="true"
							aria-expanded="false"
						>
							<div class="d-flex align-items-center w-100">
								<p class="ad-uc__drop-wrap--text m-0 mr-2">{{ choosedSpeaker }} {{ $t('speaker') }}</p>
								<svg xmlns="http://www.w3.org/2000/svg" width="10" height="6" viewBox="0 0 10 6" fill="none">
									<path d="M1 1L5 5L9 1" stroke="white"/>
								</svg>
							</div>
						</button>
						<div class="dropdown-menu dropdown-menu-right mt-1" :aria-labelledby="`${_uid}__choose-speaker`">
							<div class="select-speaker px-1">
								<button v-for="(speaker, index) in speakers" :key="index" class="btn btn-choose d-block w-100 text-left" @click="formData.speaker = speaker.id">
									{{ speaker.id }} {{ $t('speaker') }}
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="cxad-uc__inner--padding">
				<div class="ad-uc__drop-wrap py-2 px-3 d-flex align-items-center justify-content-between w-100">
					<p class="ad-uc__drop-wrap--text m-0">{{ $t('target-language') }}</p>
					<div class="dropdown" ref="dropdownLang">
						<button
							:id="`${_uid}__choose-language`"
							ref='buttonLangTarget'
							class="btn btn-dropdown p-0 d-flex align-items-center"
							type="button"
							data-toggle="dropdown"
							aria-haspopup="true"
							aria-expanded="false"
						>
							<div class="d-flex align-items-center w-100">
								<div class="d-flex">
									<img
										:src="formData?.targetLang?.flagSrc"
										class="img-fluid mr-2"
										style="width: 18px; height: 18px"
									/>
									<p class="ad-uc__drop-wrap--text m-0 mr-2">{{ formData?.targetLang?.name.toUpperCase() }}</p>
								</div>
								<svg xmlns="http://www.w3.org/2000/svg" width="10" height="6" viewBox="0 0 10 6" fill="none">
									<path d="M1 1L5 5L9 1" stroke="white"/>
								</svg>
							</div>
						</button>
						<div class="dropdown-menu dropdown-menu-lang dropdown-menu-right mt-1" ref="dropdownLangMenu" :aria-labelledby="`${_uid}__choose-language`">
							<div class="px-1">
								<div class="select-lang overflow-auto px-1">
									<button v-for="country in sortedCountries" :key="country.code + country.name" class="btn btn-choose d-block w-100 text-left" @click="formData.targetLang = country">
										<div class="d-flex align-items-center">
											<img
												:src="country.flagSrc"
												class="img-fluid"
												style="width: 18px; height: 18px"
											/>
											<p class="ad-uc__drop-wrap--text m-0 ml-2">{{ country.name }}</p>
											<img src="@/assets/images/icons/ic_news.svg" alt="" class="ml-2" v-if="country.name == 'Multilingual'">
										</div>
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="ad-uc__check d-flex align-items-center cxad-uc__inner--padding">
				<vs-checkbox :disabled="isSubmit" color="#6D75F6" id="includesBgAu" v-model="formData.additionalInclude"></vs-checkbox>
				<label for="includesBgAu" class="ad-uc__text ad-uc__label-check m-0">{{ $t('skip-preview') }}</label>
			</div>

			<p class="ad-uc__label-text3 text-white m-0 cxad-uc__inner--padding">{{ $t('example-results') }} <span v-if="queue > 0">|</span> <span v-if="queue > 0" style="color: #6D75F6;"> {{ queue }}{{ $t('example-results--link') }}</span></p>
			<div class="position-relative w-100">
				<div ref="listExample" class="ad-uc__list-example w-100 d-flex">
					<div v-for="(data, index) in listPreview" :key="index" class="ad-uc__card-video position-relative cursor-pointer" @click="$emit('click:play-preview', data.url)">
						<img :src="data.thumbnail" alt="" class="ad-uc__card-img">
						<div class="t__action-play">
							<button
								type="button"
								class="t__toggle btn p-0 rounded-0"
								@click="$emit('click:play-preview', data.url)"
							>
								<font-awesome-icon
									icon="fa-solid fa-play-circle"
									class="t__toggle-icon"
								/>
							</button>
						</div>
					</div>
				</div>
				<div ref="dividerLeft" class="divider-left"></div>
				<div ref="dividerRight" class="divider-right"></div>
			</div>

			<!-- <hr style="background: #2d2d2d;" class="mb-0">

			<div class="cxad-uc__inner--padding">
				<button v-if="!formData.fileSrt1.file && !formData.fileSrt2.file" class="btn button--srt p-0 d-flex align-items-center" @click="$bvModal.show('upload-srt-modal')">
					<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
						<path fill-rule="evenodd" clip-rule="evenodd" d="M5.3125 6.25C5.3125 5.33832 5.67466 4.46398 6.31932 3.81932C6.96398 3.17466 7.83832 2.8125 8.75 2.8125H18.7338C19.081 2.8127 19.4232 2.89556 19.7321 3.05424C20.0409 3.21291 20.3076 3.44284 20.51 3.725L24.2775 8.97125C24.5438 9.34375 24.6875 9.79 24.6875 10.2462V23.75C24.6875 24.6617 24.3253 25.536 23.6807 26.1807C23.036 26.8253 22.1617 27.1875 21.25 27.1875H8.75C7.83832 27.1875 6.96398 26.8253 6.31932 26.1807C5.67466 25.536 5.3125 24.6617 5.3125 23.75V6.25ZM8.75 4.6875C7.8875 4.6875 7.1875 5.3875 7.1875 6.25V23.75C7.1875 24.6125 7.8875 25.3125 8.75 25.3125H21.25C22.1125 25.3125 22.8125 24.6125 22.8125 23.75V11.1213H18.75C18.5014 11.1213 18.2629 11.0225 18.0871 10.8467C17.9113 10.6708 17.8125 10.4324 17.8125 10.1838V4.6875H8.75Z" fill="white"/>
						<path d="M18.8572 16.5237C18.7803 16.62 18.6851 16.7002 18.5772 16.7596C18.4692 16.8191 18.3506 16.8567 18.2281 16.8703C18.1057 16.8839 17.9817 16.8732 17.8634 16.8388C17.745 16.8044 17.6346 16.7471 17.5385 16.67L15.9385 15.3875V19.6875C15.9385 19.9361 15.8397 20.1746 15.6639 20.3504C15.488 20.5262 15.2496 20.625 15.001 20.625C14.7523 20.625 14.5139 20.5262 14.338 20.3504C14.1622 20.1746 14.0635 19.9361 14.0635 19.6875V15.3875L12.461 16.67C12.2667 16.8253 12.0187 16.8971 11.7715 16.8696C11.5243 16.842 11.2981 16.7174 11.1428 16.5231C10.9875 16.3289 10.9157 16.0808 10.9433 15.8336C10.9708 15.5864 11.0954 15.3603 11.2897 15.205L14.411 12.7075C14.5756 12.5745 14.7805 12.5013 14.9922 12.5H15.0022C15.2272 12.5 15.4322 12.58 15.5935 12.7125L18.711 15.205C18.8072 15.2819 18.8874 15.3771 18.9468 15.485C19.0063 15.593 19.0439 15.7116 19.0575 15.8341C19.0711 15.9565 19.0604 16.0805 19.026 16.1988C18.9916 16.3172 18.9343 16.4276 18.8572 16.5237Z" fill="white"/>
					</svg>
					<p class="ad-uc__label-text3 text-white m-0">Upload SRT (optional)</p>
				</button>
				<div v-else class="container-fluid">
					<div class="row">
						<div v-if="formData.fileSrt1.file" class="col-6 pr-2">
							<p class="ad-uc__label-text2 mb-2 text-white">Original Language SRT</p>
							<div class="ad-uc__srt--wrap d-flex align-items-center justify-content-between">
								<p class="m-0 text-truncate mr-2" :title="formData.fileSrt1.fileName">{{ formData.fileSrt1.fileName || '-.srt' }}</p>
								<button title="delete file" class="btn--delete-file btn d-flex align-items-center justify-content-center" @click="deleteOriginalSrtFile()">
									<img src="@/assets/images/icons/ic_usrt-delete.svg" alt="">
								</button>
							</div>
						</div>
						<div v-if="formData.fileSrt2.file" class="col-6 pl-2">
							<p class="ad-uc__label-text2 mb-2 text-white">Translated Language SRT</p>
							<div class="ad-uc__srt--wrap d-flex align-items-center justify-content-between">
								<p class="m-0 text-truncate mr-2" :title="formData.fileSrt2.fileName">{{ formData.fileSrt2.fileName || '-.srt' }}</p>
								<button title="delete file" class="btn--delete-file btn d-flex align-items-center justify-content-center" @click="deleteTanslatedSrtFile()">
									<img src="@/assets/images/icons/ic_usrt-delete.svg" alt="">
								</button>
							</div>
						</div>
					</div>
				</div>
			</div> -->
		</div>
		<input type="file" class="d-none" id="uploadVideo" accept=".mp4,.mp3,.webm,.wav" @change="uploadFile">
		<video controls :src="formData.fileVideo.src" class="d-none" ref="videoElement"></video>
	</div>
</template>

<script>
import { AlertUtils } from "@/mixins/AlertUtils";
// import ffmpeg from 'ffmpeg.js/ffmpeg-mp4.js';

export default {
	mixins: [AlertUtils],
	props: {
		languages: {
			type: Array,
			default: () => {
				return []
			}
		},
		selectedLanguage: {
			type: Object,
			default: () => ({
				id: '6401ae940eb4d9111ec260d7',
				name: 'Indonesia',
				code: 'ID',
				flagSrc: require('@/assets/images/icons/ic_flag_indonesia.svg'),
			}),
		},
		value: {
			type: Object,
			default: () => {
				return {}
			}
		},
		listPreview: {
			type: Array,
			default: () => {
				return {}
			}
		},
		isSubmit: {
			type: Boolean,
			default: false
		},
		openLang: {
			type: Boolean,
			default: false
		},
		queue: {
			type: Number,
			default: 0
		}
	},
	data() {
		return {
			speakers: [
				// {
				// 	id: 'auto_detect',
				// 	label: '✨Auto detect'
				// },
				{
					id: 1,
					label: '1 speaker'
				},
				{
					id: 2,
					label: '2 speaker'
				},
				{
					id: 3,
					label: '3 speaker'
				},
			],

			internalSelectedLanguage: this.selectedLanguage,
			formData: this.value,

			typeUpload: 'video',
		}
	},
	watch: {
		value: {
			handler(newValue) {
				this.formData = newValue
			},
			deep: true
		},
		formData: {
			handler(newValue) {
				this.$emit('input', newValue)
			},
			deep: true
		},
		openLang: {
			handler(newValue) {
				if(newValue) {
					this.toggleDropdownLang()
				}
			},
			deep: true
		}
	},
	computed: {
		sortedCountries() {
			if (this.languages.length > 0) {
				const sortedLanguages = [...this.languages];

				sortedLanguages.sort((a, b) => {
				const nameA = a.name.toLowerCase();
				const nameB = b.name.toLowerCase();

				if (nameA === 'multilingual') {
					return -1; // Pindahkan 'Multilingual' ke atas
				}

				if (nameB === 'multilingual') {
					return 1; // Pindahkan 'Multilingual' ke atas
				}

				if (nameA < nameB) {
					return -1;
				}

				if (nameA > nameB) {
					return 1;
				}

				return 0;
				});

				return sortedLanguages;
			} else {
				return this.languages;
			}
		},
		choosedSpeaker() {
			let index = this.speakers.findIndex(item => item.id === this.formData.speaker);
			return this.speakers[index].id || null
		}
	},
	methods: {
		handleScroll() {
			const listExample = this.$refs.listExample;
			const dividerLeft = this.$refs.dividerLeft;
			const dividerRight = this.$refs.dividerRight;

			if (listExample.scrollLeft === 0) {
				dividerLeft.style.opacity = '0';
				dividerRight.style.opacity = '1'; // Tampilkan divider-right
			} else if (listExample.scrollLeft + listExample.clientWidth === listExample.scrollWidth) {
				dividerLeft.style.opacity = '1'; // Tampilkan divider-left
				dividerRight.style.opacity = '0';
			} else {
				dividerLeft.style.opacity = '1';
				dividerRight.style.opacity = '1';
			}
		},
		uploadFile(event) {
			const file = event.target.files[0];
			if (!file) return;

			// Validasi ekstensi file
			const allowedExtensions = ['.mp4', '.mp3', '.webm', '.wav'];
			const fileExtension = file.name.substring(file.name.lastIndexOf('.'));
			let type = null
			if (fileExtension === '.mp3' || fileExtension === '.wav') {
				type = 'audio';
			} else {
				type = 'video'
			}

			if (!allowedExtensions.includes(fileExtension)) {
				this.alertFailIcon('File Extension not allowed');
				return;
			}

			const maxSizeMB = 50;
			const maxSizeBytes = maxSizeMB * 1024 * 1024;

			if (file.size > maxSizeBytes) {
				this.alertFailIcon('File size must less than 50MB');
				return;
			}

			if(type == 'video') {
				const video = document.createElement('video');
				const url = URL.createObjectURL(file);
				video.preload = 'metadata';
				video.onloadedmetadata = function () {
					if(video.duration == Infinity) {
						this.typeUpload = 'infinity'
						this.$emit('duration:type', 'infinity')
						this.$bvModal.show('video-duration-exceeds');
						URL.revokeObjectURL(url);
					} else if (video.duration > 180) { // 180 detik = 3 menit
						this.typeUpload = type
						this.$emit('duration:type', type)
						this.$bvModal.show('video-duration-exceeds');
						URL.revokeObjectURL(url);
					} else {
						const reader = new FileReader();
						reader.onload = () => {
							const fileDataUrl = reader.result;
							this.formData.fileVideo.src = fileDataUrl;
							// this.formData.fileVideo.file = file;
							this.formData.fileVideo.name = file.name;
							this.formData.fileVideo.type = type;
							this.formData.fileVideo.fileSize = file.size;
							// this.generateThumbnail(fileDataUrl);
							this.createVideoChunks(file);
						};
						reader.readAsDataURL(file);
					}
				}.bind(this);
				video.src = url;
			} else if (type === 'audio') {
				const audio = document.createElement('audio');
				const url = URL.createObjectURL(file);
				audio.preload = 'metadata';
				audio.onloadedmetadata = function () {
					if(audio.duration == Infinity) {
						this.typeUpload = 'infinity'
						this.$emit('duration:type', 'infinity')
						this.$bvModal.show('video-duration-exceeds');
						URL.revokeObjectURL(url);
					} else if (audio.duration > 180) { // 180 seconds = 3 minutes
						this.typeUpload = type
						this.$emit('duration:type', type)
						this.$bvModal.show('video-duration-exceeds');
						URL.revokeObjectURL(url);
					} else {
						const reader = new FileReader();
						reader.onload = () => {
							const fileDataUrl = reader.result;
							this.formData.fileVideo.src = fileDataUrl;
							this.formData.fileVideo.file = file;
							this.formData.fileVideo.name = file.name;
							this.formData.fileVideo.type = type;
							this.formData.fileVideo.fileSize = file.size;
							// this.createVideoChunks(file);
						};
						reader.readAsDataURL(file);
					}
				}.bind(this);
				audio.src = url;
			 } else {
				const reader = new FileReader();
				reader.onload = () => {
				const fileDataUrl = reader.result;
					this.formData.fileVideo.src = fileDataUrl
					this.formData.fileVideo.file = file
					this.formData.fileVideo.name = file.name
					this.formData.fileVideo.type = type
					this.formData.fileVideo.fileSize = file.size
					// this.createVideoChunks(file);
				};
				reader.readAsDataURL(file);
			}

		},
		handleDrag(event) {
     		const fileDrag = [...event.dataTransfer.files];
			const file = fileDrag[0];
			if (!file) return;

			// Validasi ekstensi file
			const allowedExtensions = ['.mp4', '.mp3', '.webm', '.wav'];
			const fileExtension = file.name.substring(file.name.lastIndexOf('.'));
			let type = null
			if (fileExtension === '.mp3' || fileExtension === '.wav') {
				type = 'audio';
			} else {
				type = 'video'
			}

			if (!allowedExtensions.includes(fileExtension)) {
				this.alertFailIcon('File Extension not allowed');
				return;
			}

			if (!allowedExtensions.includes(fileExtension)) {
				this.alertFailIcon('File Extension not allowed');
				return;
			}

			const maxSizeMB = 50;
			const maxSizeBytes = maxSizeMB * 1024 * 1024;

			if (file.size > maxSizeBytes) {
				this.alertFailIcon('File size must less than 50MB');
				return;
			}

			if(type == 'video') {
				const video = document.createElement('video');
				const url = URL.createObjectURL(file);
				video.preload = 'metadata';
				video.onloadedmetadata = function () {
					if(video.duration == Infinity) {
						this.typeUpload = 'infinity'
						this.$emit('duration:type', 'infinity')
						this.$bvModal.show('video-duration-exceeds');
						URL.revokeObjectURL(url);
					} else if (video.duration > 180) { // 180 detik = 3 menit
						this.typeUpload = type
						this.$emit('duration:type', type)
						this.$bvModal.show('video-duration-exceeds');
						URL.revokeObjectURL(url);
					} else {
						const reader = new FileReader();
						reader.onload = () => {
							const fileDataUrl = reader.result;
							this.formData.fileVideo.src = fileDataUrl;
							// this.formData.fileVideo.file = file;
							this.formData.fileVideo.name = file.name;
							this.formData.fileVideo.type = type;
							this.formData.fileVideo.fileSize = file.size;
							// this.generateThumbnail(fileDataUrl);
							this.createVideoChunks(file);
						};
						reader.readAsDataURL(file);
					}
				}.bind(this);
				video.src = url;
			} else if (type === 'audio') {
				const audio = document.createElement('audio');
				const url = URL.createObjectURL(file);
				audio.preload = 'metadata';
				audio.onloadedmetadata = function () {
					if(audio.duration == Infinity) {
						this.typeUpload = 'infinity'
						this.$emit('duration:type', 'infinity')
						this.$bvModal.show('video-duration-exceeds');
						URL.revokeObjectURL(url);
					} else if (audio.duration > 180) { // 180 seconds = 3 minutes
						this.typeUpload = type
						this.$emit('duration:type', type)
						this.$bvModal.show('video-duration-exceeds');
						URL.revokeObjectURL(url);
					} else {
						const reader = new FileReader();
						reader.onload = () => {
							const fileDataUrl = reader.result;
							this.formData.fileVideo.src = fileDataUrl;
							this.formData.fileVideo.file = file;
							this.formData.fileVideo.name = file.name;
							this.formData.fileVideo.type = type;
							this.formData.fileVideo.fileSize = file.size;
							// this.createVideoChunks(file);
						};
						reader.readAsDataURL(file);
					}
				}.bind(this);
				audio.src = url;
			 } else {
				const reader = new FileReader();
				reader.onload = () => {
				const fileDataUrl = reader.result;
					this.formData.fileVideo.src = fileDataUrl
					this.formData.fileVideo.file = file
					this.formData.fileVideo.name = file.name
					this.formData.fileVideo.type = type
					this.formData.fileVideo.fileSize = file.size
					// this.createVideoChunks(file);
				};
				reader.readAsDataURL(file);
			}
		},
		// async uploadVideo(file) {
			// console.log(`Ukuran file asli: ${file.size} bytes`);
			// const ffmpegModule = await ffmpeg.createFFmpeg();
			// await ffmpegModule.load();
			// await ffmpegModule.FS('writeFile', 'input.mp4', new Uint8Array(await file.arrayBuffer()));
			// await ffmpegModule.run('-i', 'input.mp4', '-vf', 'scale=640:480', 'output.mp4');

			// const outputData = ffmpegModule.FS('readFile', 'output.mp4');
			// const compressedVideoBlob = new Blob([outputData.buffer], { type: 'video/mp4' });

			// console.log(`Ukuran file setelah kompresi: ${compressedVideoBlob.size} bytes`);
			// this.createVideoChunks(compressedVideoBlob);
		// },
		createVideoChunks(file) {
			const chunkSize = 4 * 1024 * 1024;
			this.formData.fileVideo.file = [];

			for (let start = 0; start < file.size; start += chunkSize) {
				const chunk = file.slice(start, start + chunkSize);
				this.formData.fileVideo.file.push(chunk);
			}

			// const reader = new FileReader();
			// let offset = 0;
			// const videoChunks = [];

			// reader.onload = () => {
			// 	const chunk = reader.result;
			// 	videoChunks.push(chunk);

			// 	offset += chunkSize;
			// 	if (offset < file.size) {
			// 		readNextChunk();
			// 	} else {
			// 		const blob = new Blob(videoChunks, { type: file.type });
			// 		this.formData.fileVideo.file = [];
      		// 		this.formData.fileVideo.file.push(blob);
			// 	}
			// };

			// function readNextChunk() {
			// 	const slice = file.slice(offset, offset + chunkSize);
			// 	reader.readAsArrayBuffer(slice);
			// }

			// readNextChunk();
		},
		generateThumbnail(src) {
			const videoElement = this.$refs.videoElement;
			videoElement.src = src;

			videoElement.addEventListener('loadeddata', () => {
				// Capture the first frame as a thumbnail
				const canvas = document.createElement('canvas');
				canvas.width = videoElement.videoWidth;
				canvas.height = videoElement.videoHeight;
				canvas.getContext('2d').drawImage(videoElement, 0, 0, canvas.width, canvas.height);
				const thumbnailDataUrl = canvas.toDataURL('image/jpeg'); // You can adjust the format as needed

				this.formData.fileVideo.thumbnail = thumbnailDataUrl;
			});
		},
		deleteOriginalSrtFile() {
			this.formData.fileSrt1.file = null
			this.formData.fileSrt1.fileName = null
			this.formData.fileSrt1.lang = null
		},
		deleteTanslatedSrtFile() {
			this.formData.fileSrt2.file = null
			this.formData.fileSrt2.fileName = null
			this.formData.fileSrt2.lang = null
		},
		toggleDropdownLang() {
			if (this.$refs.buttonLangTarget) {
				setTimeout(() => {
					this.$refs.buttonLangTarget.click();
				}, 100)
			}
		},

	},
	mounted() {
		const listExample = this.$refs.listExample;
    	listExample.addEventListener('scroll', this.handleScroll);
		this.handleScroll();
	},
	beforeDestroy() {
		const listExample = this.$refs.listExample;
		listExample.removeEventListener('scroll', this.handleScroll);
	},
}
</script>

<style scoped>
.ad-uc {
	padding: 2px;
	background-image: linear-gradient(to bottom right, #FFFFFF33, #FFFFFF11);
	border-radius: 8px;
}

.ad-uc__inner {
	background: linear-gradient(0deg, #121418 0%, #121418 100%), rgba(49, 48, 54, 0.30);
	border-radius: 8px;
	backdrop-filter: blur(2px);
	gap: 16px;
	padding-top: 22px;
	padding-bottom: 22px;
}

.cxad-uc__inner--padding {
	padding-left: 22px;
	padding-right: 22px;
}

.ad-uc__icon-info {
	right: 6px;
	top: 6px;
}

.ad-uc__info--inner {
	left: 0;
	width: 350px;
	border-radius: 6px;
	background-color: #1F1F1F;
	box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.30);

	visibility: hidden;
	opacity: 0;
	transition: opacity 0.25s;
}

.ad-uc__icon-info:hover .ad-uc__info--inner {
	visibility: visible;
	opacity: 1;
}

.ad-uc__info--title, .ad-uc__info--inner ul li {
	font-size: 10px;
}

.ad-uc__info--title {
	font-weight: 600;
}

.ad-uc__label {
	border-radius: 10px;
	border: 1px dashed #2D2D2D;
	background-color: #000;
	gap: 12px;
	cursor: pointer;

	transition: background-color 0.25s;
}

.ad-uc__label:hover {
	background-color: #0e0e0e;
}

.ad-uc__label-text1 {
	color: #6D75F6;
	font-size: 16px;
	font-weight: 500;
}

.ad-uc__label-text2, .ad-uc__label-text3 {
	color: #979797;
	font-weight: 400;
}

.ad-uc__label-text2 {
	font-size: 10px;
}

.ad-uc__label-text3 {
	font-size: 12px;
}

.ad-uc__drop-wrap {
	border-radius: 4px;
	border: 1px solid #2D2D2D;
}

.ad-uc__drop-wrap--text {
	color: #FFF;
	font-size: 14px;
	font-weight: 400;
}

.btn-choose {
	font-size: 12px;
	color: #fff;
	font-weight: 400;
}

.dropdown-menu {
	min-width: 8rem;
	border-radius: 6px;
	background: #1F1F1F;
	box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.85);
}

.dropdown-menu-lang {
	width: 12rem;
}

.select-lang {
  width: 100% !important;
  height: 12rem;
  padding: 10px 10px;
}

.select-lang::-webkit-scrollbar {
  width: 3px;
}

.select-lang::-webkit-scrollbar-track {
  background: #636363;
  border-radius: 4px;
}

.select-lang::-webkit-scrollbar-thumb {
  background: #B6B6B6;
  border-radius: 4px;
  background-clip: padding-box;
}

.select-lang::-webkit-scrollbar-thumb:hover {
  background: #888;
}

.ad-uc__label-check {
	color: #FFF;
	font-size: 10px;
	font-weight: 400;
}

.ad-uc__list-example {
	overflow-x: auto;
	gap: 12px;
	scrollbar-width: thin;
	scrollbar-color: transparent transparent;
}

.ad-uc__list-example > *:first-child {
	margin-left: 22px;
}

.ad-uc__list-example > *:last-child {
	margin-right: 22px;
}

.ad-uc__list-example::-webkit-scrollbar {
  display: none;
}

.ad-uc__card-video {
	min-width: 207px;
	max-width: 207px;
	height: 116px;
	border-radius: 6px;
}

.ad-uc__card-img {
	width: 100%;
	height: 100%;
	object-fit: cover;
    object-position: center center;
	border-radius: 6px;
}

.button--srt {
	gap: 6px;
}

.divider-left, .divider-right {
	opacity: 0;
	transition: opacity 0.3s ease;
}

.divider-right {
    top: 0;
    right: -2px;
    width: 74px;
    height: 100%;
    /* transform: rotate(270deg); */
    position: absolute;
    background: linear-gradient(88deg, rgba(18, 20, 24, 0.00) 6.25%, #121418 100%);
}

.divider-left {
	top: 0;
    left: -1px;
    width: 74px;
    height: 100%;
    position: absolute;
    /* transform: rotate(90deg); */
    background: linear-gradient(273deg, rgba(18, 20, 24, 0.00) 6.25%, #121418 100%);
}

.ad-uc__srt--wrap {
	padding: 6px;
	background-color: #1f1f1f;
	color: #fff;
	font-size: 12px;
	font-style: normal;
	font-weight: 600;
	border-radius: 6px;
    width: 100%;
}

.btn--delete-file {
	padding: 2px;
	transition: background-color 0.25s;
}

.btn--delete-file:hover {
	background-color: #383838;
}
</style>

<style>
.ad-uc__check .vs-checkbox {
	border: 2px solid white !important;
	background-color: transparent;
	border-radius: 4px;
	width: 16px;
	height: 16px;
}
.ad-uc__check .vs-checkbox--input:checked+.vs-checkbox {
	border: 2px solid rgb(109, 117, 246) !important;
}

.ad-uc__check .con-vs-checkbox {
	margin-left: 0px;
}

.ad-uc__check .vs-checkbox--icon {
	font-size: 12px;
}

.video__wrapper {
	height: 140px;
	border-radius: 10px;
}

.video__wrapper--video {
	width: 100%;
	height: 100%;
	border-radius: 10px;
}

.t__action-play {
    width: 100%;
    position: absolute;
    bottom: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.t__toggle {
  background: none;
  /* filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.20)); */
  /* filter: drop-shadow(13px 18px 14px #000); */
  filter: drop-shadow(13px 7px 14px #000);
}

.t__toggle-icon {
  font-size: 26px;
  color: #FFFFFF;
}
</style>