<template>
	<div class="adp w-100 h-100 d-flex flex-column">
		<div class="auto-dubbing__subscription d-flex align-items-center ml-auto">
			<div class="adp__dropdown dropdown d-flex align-items-center">
				<img
					:src="require('@/assets/images/icons/ic_subscription_info.svg')"
					class="auto-dubbing__subscription-icon"
					alt=""
				/>
				<div class="adp__tooltip dropdown-menu">
					<span>Your video duration left. <b class="cursor-pointer gv__tooltip--topup" @click="$router.push({ name : 'TopUp' })">{{ $t('top-up-more') }}</b></span>
          			<div class="gv__tooltip--package d-flex align-items-center mt-2">
						<div class="gv__tooltip--package-wrapper p-2 w-100">
							<div class="gv__tooltip--package-wrapper-inner d-flex align-items-start">
								<div class="gv__tooltip--package-quota d-flex flex-column align-items-center justify-content-center w-100">
									<p class="m-0 tooltip--package__text">Multilingual</p>
									<p class="m-0 tooltip--package__quota">{{ allocation?.audio_multilingual_generates_quota || '0' }}<span class="tooltip--package__text"> {{ $t('mins') }}</span><span class="tooltip--package__exp ml-3 d-none" v-if="allocation?.expired_date && allocation?.audio_multilingual_generates_quota">exp. {{ getMonthDate(allocation?.expired_date) }}</span></p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<p class="auto-dubbing__subscription-text mb-0 ml-1">{{allocation?.audio_multilingual_generates_quota || '0'}} {{ $t('mins') }}</p>
			</div>
			<button :disabled="isSubmit" class="auto-dubbing__upgrade-button btn" @click="$router.push({ name : 'TopUp' })">{{ $t('upgrade-lower') }}</button>
		</div>
		<div v-if="isSubmit" class="auto-dub__submit d-flex flex-column align-items-center justify-content-center w-100 h-100 px-5">
			<vue-lottie
				:options="animationOptions"
				:height="300"
			></vue-lottie>
			<div class="progress auto-dub__progress w-100 mx-5">
				<div class="progress-bar" role="progressbar" :style="'width:' + actualProgress + '%'" :aria-valuenow="progress" aria-valuemin="0" aria-valuemax="100"></div>
			</div>
			<p class="auto-dub__submit--text mb-0 mt-4">{{ $t('transcribing-translatig') }}</p>
		</div>
	</div>
</template>

<script>
import VueLottie from 'vue-lottie';
import loadAutoDub from '@/assets/animations/load_autodub2.json';

export default {
	components: {
		VueLottie,
	},
	props: {
		isSubmit: {
			type: Boolean,
			default: false
		},
		progress: {
			type: Number,
			default: 0
		},
		allocation: {
			type: Object,
			default: () => {
				return {}
			}
		}
	},
	data() {
		return {
			animationOptions: {
				animationData: loadAutoDub,
				loop: true,
				autoplay: true,
				scale: 0.1,
			},
			actualProgress: this.progress
		}
	},
	watch: {
		progress: {
			handler(newValue) {
				this.actualProgress = newValue
			},
			depp: true
		}
	},
	methods:{
		getMonthDate(dateTime) {
			const months = [
				"Jan",
				"Feb",
				"Mar",
				"Apr",
				"May",
				"Jun",
				"Jul",
				"Aug",
				"Sep",
				"Oct",
				"Nov",
				"Dec",
			];

			const dateObject = new Date(dateTime);
			const tanggal = dateObject.getUTCDate();
			const monthIndex = dateObject.getUTCMonth();
			const month = months[monthIndex];

			return `${month} ${tanggal}`;
		},
	}
}
</script>

<style scoped>
.adp {
	gap: 12px;
}

.auto-dubbing__subscription {
  gap: 12px;
}

.auto-dubbing__subscription:hover .auto-dubbing__tooltip {
  display: block;
}

.auto-dubbing__tooltip {
  left: -235px !important;
  width: auto !important;
  background-color: #1F1F1F;
  color: #ffffff;
  font-size: 12px;
  padding: 10px 15px;
}

.auto-dubbing__subscription-icon {
  height: 14px;
}

.auto-dubbing__subscription-text {
  font-size: 14px;
  font-weight: 500;
}

.auto-dubbing__upgrade-button {
	color: #6D75F6;
	border-radius: 4px;
	border: 1px solid #6D75F6;
	padding: 6px 16px;
}

.auto-dub__progress {
	border-radius: 4px;
	height: 7.3px;
	background-color: #3E4350;
}

.auto-dub__progress .progress-bar {
	background-color: #6D75F6;
	border-radius: 4px;
}

.auto-dub__submit--text {
	color: #FFF;
	font-size: 16px;
	font-weight: 400;
}

.adp__dropdown:hover .adp__tooltip {
	display: block;
}

.adp__tooltip {
	left: -222px !important;
	width: auto !important;
	background-color: #1F1F1F;
	color: #ffffff;
	font-size: 12px;
	padding: 10px 15px;
}

.gv__tooltip--topup {
	color: #6D75F6;
}

.gv__tooltip--package {
	gap: 6px;
}

.gv__tooltip--package-wrapper {
	border-radius: 4px;
	background: #2D2D2D;
}

.gv__tooltip--package-wrapper-inner {
	gap: 4px;
}

.tooltip--package__text {
	color: #FFF;
	font-family: Avenir Next;
	font-size: 10px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

.tooltip--package__quota {
	color: #FFF;
	font-family: Avenir Next;
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
}

.gv__tooltip--package-quota {
	gap: 4px;
}

.tooltip--package__exp {
	color: #8C8C8C;
	font-family: Avenir Next;
	font-size: 8px;
	font-style: italic;
	font-weight: 500;
	line-height: normal;
}
</style>