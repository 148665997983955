<template>
		<b-modal :id="id" centered header-class="border-0 pt-4 pb-0" no-close-on-backdrop :hide-footer="true" :hide-header="true" footer-class="border-0 pb-3 pt-0 mx-auto" size="xl" style="background-color: rgba(0, 0, 0, 0.5) !important; backdrop-filter: blur(5px) !important;" dialog-class="yp__dialog d-flex align-items-center justify-content-center" content-class="yp" body-class="position-relative" @hidden="handleHide">
      <div class="d-flex">
            <label  class="w-100 d-flex flex-column align-items-center text-center">
                <div class="h-100 d-flex align-items-center">
                  <div :class="{'d-flex align-items-center': load_preview}" v-show="load_preview">
                    <h3 class="text-center">Loading...</h3>
                  </div>
                  <video v-show="!load_preview" :src="url" @loadedmetadata="handleLoadMetaData" controls class="h-100 w-100" controlsList="nodownload nofullscreen" ref="videoPlayer"></video>
                </div>
            </label>
            <div class="d-flex align-items-start justify-content-end ml-3" v-if="!load_preview">
                <div class="text-right mb-3">
                    <button class="btn btn-close d-flex justify-content-center align-items-center" @click="$bvModal.hide(id)"><b-icon icon="x"></b-icon></button>
                </div>
            </div>
      </div>
    </b-modal>
</template>

<script>
export default {
	props: {
		id: {
			type: String,
			default: 'watch-youtube-embed'
		},
		url: {
			type: String,
			default: null,
		},
	},
	data() {
		return {
      load_preview: true
		}
	},

  methods: {
    handleLoadMetaData(){
			this.load_preview = false;
		},
    handleHide(){
      this.load_preview = true;
    }
  }
}
</script>

<style scoped>
.handle__load-data{
  height: 365px;
}
.btn-close {
  background-color: #ededed;
  border-radius: 50%;
  width: 15px;
  height: 26px;
}

video {
  border-radius: 10px;
}
</style>

<style>
.yp__dialog {
  max-width: none;
}

.yp {
  width: 1000px;
  background-color: transparent;
  border-radius: 10px;
}
</style>