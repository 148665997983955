<template>
	<div v-if="scripts.length > 0" class="ad-table w-100">
		<div class="ad-table__inner">
			<table class="adt">
				<thead>
					<tr>
						<th class="text-center text-nowrap">START TIME</th>
						<th class="text-center">END TIME</th>
						<th style="width:25%;">TRANSCIPT</th>
						<th class="text-center">ORIGIN</th>
						<th style="width:25%;">ADAPTATION</th>
						<th class="text-center">CLONE</th>
						<th class="text-center">ACTION</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(data, index) in scripts" :key="index">
						<td class="text-center" :class="{ 'active' : data.isEdit, 'disable' : invalidFormatIndex == index }">
							<p v-if="!data.isEdit" class="m-0">{{ data.start.d }}</p>
							<the-mask v-else v-model="data.start.d" :mask="['##:##:##,###']" :masked="true" class="ad__edit--input"/>
						</td>
						<td class="text-center" :class="{ 'active' : data.isEdit, 'disable' : invalidFormatIndex == index }">
							<p v-if="!data.isEdit" class="m-0">{{ data.end.d }}</p>
							<the-mask v-else v-model="data.end.d" :mask="['##:##:##,###']" :masked="true" class="ad__edit--input"/>
						</td>
						<td :class="{ 'active' : data.isEdit, 'disable' : invalidFormatIndex == index }">
							<p v-if="!data.isEdit" class="m-0">{{ data.text }}</p>
							<div v-else class="d-flex flex-column">
								<ADScriptEditor v-model="data.text" fullscreen small @click:plain-text="(val) => handlePlainText(val, index)"/>
							</div>
						</td>
						<td class="text-center" :class="{ 'active' : data.isEdit, 'disable' : invalidFormatIndex == index }">
							<div class="d-flex align-items-center">
								<img :src="data.img" alt="" class="speaker--img mr-2">
								<p class="text-nowrap m-0">{{ speaker(data.speaker) }}</p>
							</div>
						</td>
						<td :class="{ 'active' : data.isEdit, 'disable' : invalidFormatIndex == index }">
							<p v-if="!data.isEdit" class="m-0">{{ data.translate }}</p>
							<div v-else class="d-flex flex-column">
								<ADScriptEditor v-model="data.translate" small/>
							</div>
						</td>
						<td class="text-center" :class="{ 'active' : data.isEdit, 'disable' : invalidFormatIndex == index }">
							<div class="clone--td position-relative d-flex align-items-center justify-content-center">
								<img :src="data.img" alt="" class="speaker--img">
								<img src="@/assets/images/icons/ic_voice-autodub.svg" alt="" class="icon--voice position-absolute">
							</div>
						</td>
						<td class="text-center ">
							<button :disabled="isDisabled" v-if="data.isEdit" @click="handleClickAction(index)" class="btn p-0 d-flex btn-save--table flex-column align-items-center justify-content-center mx-auto">
								<img src="@/assets/images/icons/ic_check-autodub.svg" alt="">
								<span>save</span>
							</button>
							<button :disabled="isDisabled || hasEdits" v-else class="btn p-0" @click="handleClickAction(index)"><img src="@/assets/images/icons/ic_pencil-autodub.svg" alt=""></button>
						</td>
						<ADTranslationCorrection :id="`translation-correction--table-${index}`" :languages="languages" :langSource="data.lang_source" :langTarget="data.lang_target" :plain-text="plainText" @click:replace-translate="handleReplaceTranslate" @click:reset-translate-correction="handleResetTC"/>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</template>

<script>
import ADTranslationCorrection from "@/components/auto-dubbing/modal/TranslationCorrection.vue"
import ADScriptEditor from '@/components/autodub-editor/ADScriptEditor';

export default {
	components: {
		ADTranslationCorrection,
		ADScriptEditor
	},
	props: {
		languages: {
			type: Array,
			default: () => {
				return []
			}
		},
		value: {
			type: Array,
			default: () => {
				return []
			}
		}
	},
	watch: {
		value: {
			handler(newValue) {
				this.scripts = newValue
			},
			deep: true
		},
		scripts: {
			handler(newValue) {
				this.$emit('input', newValue)
			},
			deep: true
		}
	},
	computed: {
		isDisabled() {
			return this.invalidFormatIndex !== -1;
		},
		invalidFormatIndex() {
			for (let i = 0; i < this.scripts.length; i++) {
				if (!this.isValidFormat(this.scripts[i])) {
					return i;
				}
			}
			return -1; // Return -1 if all objects have valid format
		},
		hasEdits() {
			return this.scripts.some(item => item.isEdit === true);
		},
	},
	data() {
		return {
			plainText: null,
			scriptIndex: null,
			scripts: this.value,
			dummy : [
				{
					startTime : '00:00:00,000',
					endTime: '00:00:06,000',
					transcript: '4.5 billion years ago, a dense cloud of interstellar gas and dust collapsed, china called this 末日',
					origin: 'Speaker 1',
					adaptation: '4.5 billion years ago, a dense cloud of interstellar gas and dust collapsed, china called this 末日',
					isEdit: false
				},
				{
					startTime : '00:00:00,000',
					endTime: '00:00:06,000',
					transcript: '4.5 billion years ago, a dense cloud of interstellar gas and dust collapsed, china called this 末日',
					origin: 'Speaker 1',
					adaptation: '4.5 billion years ago, a dense cloud of interstellar gas and dust collapsed, china called this 末日',
					isEdit: false
				},
				{
					startTime : '00:00:00,000',
					endTime: '00:00:06,000',
					transcript: '4.5 billion years ago, a dense cloud of interstellar gas and dust collapsed, china called this 末日',
					origin: 'Speaker 1',
					adaptation: '4.5 billion years ago, a dense cloud of interstellar gas and dust collapsed, china called this 末日',
					isEdit: false
				},
				{
					startTime : '00:00:00,000',
					endTime: '00:00:06,000',
					transcript: '4.5 billion years ago, a dense cloud of interstellar gas and dust collapsed, china called this 末日',
					origin: 'Speaker 1',
					adaptation: '4.5 billion years ago, a dense cloud of interstellar gas and dust collapsed, china called this 末日',
					isEdit: false
				},
				{
					startTime : '00:00:00,000',
					endTime: '00:00:06,000',
					transcript: '4.5 billion years ago, a dense cloud of interstellar gas and dust collapsed, china called this 末日',
					origin: 'Speaker 1',
					adaptation: '4.5 billion years ago, a dense cloud of interstellar gas and dust collapsed, china called this 末日',
					isEdit: false
				},
				{
					startTime : '00:00:00,000',
					endTime: '00:00:06,000',
					transcript: '4.5 billion years ago, a dense cloud of interstellar gas and dust collapsed, china called this 末日',
					origin: 'Speaker 1',
					adaptation: '4.5 billion years ago, a dense cloud of interstellar gas and dust collapsed, china called this 末日',
					isEdit: false
				},
				{
					startTime : '00:00:00,000',
					endTime: '00:00:06,000',
					transcript: '4.5 billion years ago, a dense cloud of interstellar gas and dust collapsed, china called this 末日',
					origin: 'Speaker 1',
					adaptation: '4.5 billion years ago, a dense cloud of interstellar gas and dust collapsed, china called this 末日',
					isEdit: false
				},
				{
					startTime : '00:00:00,000',
					endTime: '00:00:06,000',
					transcript: '4.5 billion years ago, a dense cloud of interstellar gas and dust collapsed, china called this 末日',
					origin: 'Speaker 1',
					adaptation: '4.5 billion years ago, a dense cloud of interstellar gas and dust collapsed, china called this 末日',
					isEdit: false
				},
				{
					startTime : '00:00:00,000',
					endTime: '00:00:07,000',
					transcript: '4.5 billion years ago, a dense cloud of interstellar gas and dust collapsed, china called this 末日',
					origin: 'Speaker 1',
					adaptation: '4.5 billion years ago, a dense cloud of interstellar gas and dust collapsed, china called this 末日',
					isEdit: false
				},
			]
		}
	},
	methods:{
		handlePlainText(val, index) {
			this.scriptIndex = index
			this.plainText = this.scripts[index].text
			console.log(val)
			this.$bvModal.show(`translation-correction--table-${index}`)
		},
		handleReplaceTranslate(val) {
			// let replacedText = this.scripts[this.scriptIndex].text.replace(this.plainText, val);
			if(val) {
				this.scripts[this.scriptIndex].translate = val
			}
		},
		handleChangeStartTime(index) {
			if (this.scripts[index].start.d > this.scripts[index].end.d) {
				this.scripts[index].start.d = this.scripts[index].end.d;
			}
		},
		handleChangeEndTime(index) {
			let lastData = this.scripts[this.scripts.length - 1]
			if (this.scripts[index].end.d > lastData.end.d) {
				this.scripts[index].end.d = lastData.end.d;
			}
		},
		handleClickAction(index) {
			this.scripts[index].isEdit = !this.scripts[index].isEdit
			if(this.scripts[index].isEdit == false) {
				this.handleChangeEndTime(index)
				this.handleChangeStartTime(index)
			}
		},
		isValidFormat(obj) {
			const formatRegex = /^(\d{2}:\d{2}:\d{2},\d{1,3})$/;
			return formatRegex.test(obj.start.d) && formatRegex.test(obj.end.d);
		},
		speaker(speaker) {
			return speaker.replace('_', ' ').toLowerCase()
		},
		handleResetTC() {
			this.plainText = null
		}
	}
}
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Fira+Code&display=swap");
.ad-table {
	padding: 2px;
	background-image: linear-gradient(to bottom right, #FFFFFF33, #FFFFFF11);
	border-radius: 8px;
}

.ad-table__inner {
	background: linear-gradient(0deg, #121418 0%, #121418 100%), rgba(49, 48, 54, 0.30);
	border-radius: 8px;
	backdrop-filter: blur(2px);
	gap: 16px;
	max-height: calc(100vh - 320px);
	overflow-y: auto;
	overflow-x: hidden;
}

.adt {
    width: 100%;
    border-collapse: collapse;
}

.adt td {
    padding: 8px;
    text-align: left;
    border: 1px solid #2D2D2D;
	color: #FFF;
	font-family: Fira Code;
	font-size: 10px;
	font-weight: 400;
}

.adt th {
    position: sticky;
    top: -1px;
	padding: 10px;
    border: 1px solid #2D2D2D;
	z-index: 2;
	color: #8C8C8C;
	font-family: Fira Code;
	font-size: 14px;
	font-weight: 400;
	background: linear-gradient(0deg, #121418 0%, #121418 100%), rgba(49, 48, 54, 0.30);
}

.adt tbody {
    /* height: 100%; */
	width: 100%;
    overflow-y: auto;
}
.active {
	background: rgba(109, 117, 246, 0.10);
}

.disable {
	background: rgba(225, 1, 1, 0.1);
}

.speaker--img {
	width: 37.646px;
	height: 37.039px;
	border-radius: 50%;
}

.icon--voice {
	right: -5px;
	top: 50%;
  	transform: translateY(-50%);
}

.btn-save--table {
	color: #6D75F6;
	font-size: 12px;
}

.ad__edit--input {
	text-align: center;
	color: #fff;
	background-color: transparent;
	border: none;
}

.ad-table__textarea {
	height: auto;
	resize: none;
	color: #fff;
	border: none;
	background-color: transparent;
}

.clone--td {
	width: fit-content;
}
</style>